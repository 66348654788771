import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const usePriceModelTypeStore = defineStore('PriceModelTypeStore', {

    state: () => {

        const priceModelTypeData : any[] = [];

        return {
            priceModelTypeData
        }

    },
    actions: {

        async getPriceModelTypes() {

            const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/priceModelType', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.priceModelTypeData = data.data;

        },
    },

    getters: {},
    persist: false,
});