import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const useMaskStore = defineStore('MaskStore', {

    state: () => {

        const maskData: any[] = [];

        return {
            maskData
        }

    },
    actions: {

        async updateSingleMask(idToUpdate: any, elementToUpdate: any) {
            const userStore = useUserStore();
                const data = await axiosInstance.put(API_URL + '/api/mask/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
        },

        async getMasks() {

            const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/mask/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.maskData = data.data;

        },
    },

    getters: {
        getMaskByPriceModelTypeId: (state) => (priceModelTypeId: number) => {

            const maskStore = useMaskStore();
            if (maskStore.maskData.length > 0) {
                return maskStore.maskData.filter(mask =>
                    mask.price_model_type_id === priceModelTypeId
                );
            }
            return [];
        },
        getPageIdByMaskObjId: (state) => (maskId: number) => {

            const maskStore = useMaskStore();

            const mask = maskStore.maskData.filter((el: any) => {
                return el?.id === maskId;
            });
            return mask[0].page_id;
        }
    },
    persist: false,
});