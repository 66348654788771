import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const usePackageModelStore = defineStore('PackageModelStore', {

        state: () => {

            const packageModelData: any[] = [];
            const packageModelDataByPriceModelData: any[] = [];

            return {
                packageModelData: packageModelData,
                packageModelDataByPriceModelData
            }

        },
        actions: {

            async getPackageModels() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/packageModel/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.packageModelData = data.data;

            },

            async getPackageModelsByPriceModelId(id: any) {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/packageModel/pricemodel/' + id, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.packageModelDataByPriceModelData = data.data;


            },


            async updateSinglePackageModel(idToUpdate: any, elementToUpdate: any) {
                const userStore = useUserStore();
                const data = await axiosInstance.put(API_URL + '/api/packageModel/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });

            },

            async createSinglePackageModel(elementToCreate: any) {
                const userStore = useUserStore();

                elementToCreate.price_model_id = Number(elementToCreate.price_model_id);

                const data = await axiosInstance.post(API_URL + '/api/packageModel', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });

            },

            async deleteSinglePackageModel(idToDelete: any) {

                const userStore = useUserStore();
                const data = await axiosInstance.delete(API_URL + '/api/packageModel/' + idToDelete, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },
        },
        getters: {},

        persist: false,
    })
;