import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const usePaymentMethodTypeStore = defineStore('PaymentMethodTypeStore', {

    state: () => {

        const paymentMethodTypeData: any[] = [];

        return {
            paymentMethodTypeData
        }

    },
    actions: {

        async getPaymentMethodTypes() {

            const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/paymentMethodType/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.paymentMethodTypeData = data.data;
        },
    },

    getters: {
        translatePaymentMethodIdToText: (state) => (paymentMethodId: number) => {

            const paymentMethodStore = usePaymentMethodTypeStore();
            const paymentMethod = paymentMethodStore.paymentMethodTypeData.find(paymentMethodType => paymentMethodType.id === Number(paymentMethodId));
            return paymentMethod?.name;

        }
    },
    persist: false,
});