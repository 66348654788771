<template>

  <div class="page-title-container">
    <h1>{{title}}</h1>
  </div>

</template>

<style scoped>

.page-title-container{

}

h1{
  font-size: 25px;
  font-family: 'Poppins', 'sans-serif';
  margin-top: 20px;
  margin-bottom: 20px;
}


</style>


<script>

export default{



props:{
  title: {
    type: String,
    required: true,
  }
},



}
</script>

