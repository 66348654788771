import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const useBloodSugarStore = defineStore('BloodSugarStore', {

        state: () => {

            const bloodSugarData: any[] = [];
            const bloodSugarTypeData: any[] = [];

            return {
                bloodSugarData,
                bloodSugarTypeData
            }
        },
        actions: {

            async updateBloodSugar() {
                const userStore = useUserStore();

                const data = await axiosInstance.put(API_URL + '/api/bloodSugar/', this.bloodSugarData, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },

            async getBloodSugarTypes() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/bloodsugartypes/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.bloodSugarTypeData = data.data;
            },

            async getBloodSugar() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/bloodsugar/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.bloodSugarData = data.data[0];
            },
        },


        persist: false,
    })
;