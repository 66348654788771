<template>

  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="updateItemConfirm">
      <v-card>
        <v-card-title>
          <div class="d-flex flex-row justify-start w-100">
            <h3>Nachrichten aktualisieren</h3>
          </div>

        </v-card-title>

        <v-card-text>
          <div class="d-flex flex-column">

            <div class="d-flex flex-row">
              <v-card-item class="w-100">
                <div class="d-flex flex-column">
                  <v-label for="name">Titel</v-label>
                  <v-text-field id="name" :readonly="true" v-model="getEditItem.content.titel" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
            </div>


            <v-card-item class="w-100">
              <div class="d-flex flex-column">
                <v-label for="description">Nachricht</v-label>
                <ckeditor :editor="editor" id="news" v-model="getEditItem.content.nachricht"
                          :config="editorConfig"
                          :disabled="true"


                />
              </div>
            </v-card-item>


            <div class="d-flex flex-row">
              <v-card-item class="w-100">
                <div class="d-flex flex-column">
                  <v-label for="author">Verfasser</v-label>
                  <v-text-field id="author" :readonly="true" v-model="getEditItem.verfasser" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">
              <v-card-item class="w-100">
                <div class="d-flex flex-column">
                  <v-label for="valid_from">gültig von</v-label>
                  <v-text-field id="valid_from" :readonly="true" :value="formatDate(getEditItem.verfassungsdatum)" type="text"
                  ></v-text-field>
                  <v-label for="valid_to">gültig bis</v-label>
                  <v-text-field id="valid-to" :readonly="true" :value="formatDate(getEditItem.verfassungsdatum)" type="text">
                  </v-text-field>
                </div>
              </v-card-item>
            </div>


          </div>
        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeEdit">Schließen</button>
          </div>

          <v-spacer></v-spacer>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import {useToast} from "vue-toastification";
import {useNewsModelStore} from "@/stores/NewsModelStore";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import {inject} from "vue";

export default {
  components: {ckeditor: CKEditor.component},



  setup(){
    const newsStore = useNewsModelStore();
    const toast = useToast();
    const moment = inject('$moment')
    return{newsStore,toast,moment}
  },


  methods: {
    closeEdit(e) {
      this.$emit("close-edit-dialog");
      e.preventDefault();
    },
    formatDate(date) {
      return this.moment(date).utc().format("LL");
    }
  },

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
      }
    }
  },

  props: {

    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    },
  },
  computed: {

    isDialogOpen() {
      return this.dialogOpen;
    },
    getEditItem() {
      return this.editItem;
    },
  }
}

</script>

<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>