<template>
  <v-app v-if="isLoggedIn">
    <v-navigation-drawer
        class="elevation-3 navigation-drawer-background-color"
        app
    >
      <div class="d-flex flex-column justify-space-between fill-height">
        <div>
          <NavigationHeader/>
          <NavigationItems v-if="hasRights === true"/>
        </div>
        <div>
          <NavigationFooter/>
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar class="elevation-2">
      <PageHeader/>
    </v-app-bar>

    <v-main v-if="hasRights === true">
      <router-view></router-view>
    </v-main>
    <v-main v-else>
      <v-sheet
          class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 ma-4"
          elevation="2" height="250" rounded max-width="800" width="100%">
        <div>
          <h2 class="h1-poppins font-weight-black main-color-1">Keine Berechtigung</h2>
          <div>
            <p class="ma-4">Ihr Account besitzt nicht die benötigten Berechtigungen um diese Anwendung zu benutzen.<br>
              Bitte loggen Sie sich aus.
            </p>
          </div>
          <button class="button-standard" @click="logOut">Logout</button>
        </div>
      </v-sheet>
    </v-main>
  </v-app>
</template>

<script>
import PageHeader from "@/components/navigation/PageHeader.vue";
import auth from "@/auth";
import NavigationItems from "@/components/navigation/NavigationItems.vue";
import NavigationHeader from "@/components/navigation/NavigationHeader.vue";
import NavigationFooter from "@/components/navigation/NavigationFooter.vue";

export default {
  data() {
    return {
      loggedIn: null,
      hasRightsForApp: false,
      loading: true,
    };
  },

  computed: {
    hasRights() {
      return this.hasRightsForApp;
    },
    isLoggedIn() {
      return auth.loggedIn();
    }
  },

  async created() {
    try {
      await auth.logIn();
      this.hasRightsForApp = await auth.hasRightsForApp();
      this.loading = false;
    } catch (error) {
      console.error("Error:", error);
    }
  },

  methods: {
    logOut() {
      auth.logOut();
    },
  },

  components: {
    NavigationFooter,
    NavigationHeader,
    NavigationItems,
    PageHeader,
  },
};
</script>


<style>

@import "./assets/css/global.css";

.v-navigation-drawer__content{
  background-color: #2F394D !important;
}

.h1-poppins {
  font-family: var(--main-font-family);
  font-weight: 400;
  font-size: 24px;
}

.v-application__wrap {
  min-height: 100vh !important;
  min-height: 100dvh !important;

}

.container-card-text {
  padding: 0;
}

.container-card-title {
  padding: 0;
}

.container-card {
  height: 100%;
  box-shadow: unset;
  background: #F8F9FA;
  padding-left: 16px;
  padding-right: 16px;
}

.v-toolbar {
  box-shadow: unset !important;
}

.v-main {
  background: #F8F9FA;
}


</style>
