import {defineStore} from "pinia";

export const useApplicationStore = defineStore('ApplicationStore', {

    state: () => {

        const PROD_API_URL: string = 'https://backoffice-backend.jg-agency.de/api/';
        const DEV_API_URL: string = 'http://localhost:3000/api/';
        const DEV_PHX_BACKEND_URL: string = 'https://phoenix-backend-stg.optadata.de';
        const PROD_PHX_BACKEND_URL: string = 'https://backend.optadata.net'; //anpassen, wenn es eine CI/CD-Pipeline gibt

        return {
            PROD_API_URL,
            DEV_API_URL,
            DEV_PHX_BACKEND_URL,
            PROD_PHX_BACKEND_URL,
        };
    },

    actions: {},

    getters: {
        getApiUrl(): string {
            if (window.location.href.includes("localhost")) {
                return this.DEV_API_URL;
            } else {
                return this.PROD_API_URL
            }
        },
        getPhxBackendUrl(): string {
            if (window.location.href.includes("localhost")) {
                return this.DEV_PHX_BACKEND_URL;
            } else {
                return this.PROD_PHX_BACKEND_URL;
            }
        }
    },
});
