<template>

  <abstract-data-table

  title="Tageszeiten"
  :is-loading="isLoading"
  :items="dayTimesStore.dayTimesData"
  :headers="headers"
  >

    <template #actions>
      <button @click="createItem" class="table-header-action-button mr-2">
        Tageszeit anlegen
      </button>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>

    </template>

    <template #item.price_model_id="{ item }">
      {{ item.price_model_id }}
    </template>

    <template #item.color="{ item }">
      <div class="colored-dot" :style="{ backgroundColor: item.color }"> </div>
    </template>

    <template #item.start_time="{ item }">
      {{ item.start_time.split(':').slice(0, 2).join(':') }}
    </template>

    <template #item.end_time="{ item }">
      {{ item.end_time.split(':').slice(0, 2).join(':') }}
    </template>

    <template #item.actions="{ item }">
      <div class="d-flex flex-row w-100 justify-center">
        <button class="table-data-action-button" @click="editItem(item)" title="Löschen">
          <v-icon>mdi-pencil</v-icon>
        </button>
      </div>
    </template>

    <template #no-data>
      <p>Keine Produkte verfügbar</p>
    </template>
  </abstract-data-table>

  <EditDayTimesDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                       @close-edit-dialog="closeDialogEdit"
                       @update-dialog-item="updateDialogItem"/>

  <CreateDayTimesDialog v-model:dialog-open="dialogCreate"
                      @close-create-item="closeCreateDialog"
                      @confirm-create-item="confirmCreateItem"/>
</template>

<script>

import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useDayTimesStore} from "@/stores/DayTimesStore";
import EditDayTimesDialog from "@/components/table/dayTimes/EditDayTimesDialog.vue";
import CreateDayTimesDialog from "@/components/table/dayTimes/CreateDayTimesDialog.vue";
import moment from "moment-timezone";

export default {
  components: {
    EditDayTimesDialog,
    CreateDayTimesDialog,
    AbstractDataTable,
  },

  setup() {

    const dayTimesStore = useDayTimesStore();
    const toast = useToast();
    const router = useRouter();

    return {
      toast,
      router,
      dayTimesStore
    };
  },

  async mounted() {
    this.isLoading = true;
    await this.dayTimesStore.getDayTimes();
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      hasEditRights: false,
      dialogEdit: false,
      dialogCreate: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        color: "",
        desc: "",
        start_time: null,
        end_time: null,
      },

      headers: [
        {title: 'Farbe', align: 'center', key: 'color', sortable: true},
        {title: 'Bezeichnung', align: 'center', key: 'description', sortable: true},
        {title: 'Beginn', align: 'center', key: 'start_time', sortable: false},
        {title: 'Ende', align: 'center', key: 'end_time', sortable: false},
        {title: 'Actions', align: 'center', key: 'actions', sortable: false},
      ],
    }
  },

  methods: {
    async refreshTable() {
      await this.dayTimesStore.getDayTimes();
      this.toast.success("Tabelle aktualisiert");
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },
    async updateDialogItem(editItemFromDialog) {

      console.log(editItemFromDialog);
      editItemFromDialog.start_time = moment(editItemFromDialog.start_time).format('HH:mm');
      editItemFromDialog.end_time = moment(editItemFromDialog.end_time).format('HH:mm');

      console.log(editItemFromDialog);

      await this.dayTimesStore.updateDayTimes(editItemFromDialog.id, editItemFromDialog);
      await this.dayTimesStore.getDayTimes();
      this.toast.success("Tageszeiten erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    confirmCreateItem(newDayTime){

      newDayTime.start_time = newDayTime.start_time.hours + ":" + newDayTime.start_time.minutes;
      newDayTime.end_time = newDayTime.end_time.hours + ":" + newDayTime.end_time.minutes;

      this.dayTimesStore.createDayTime(newDayTime);
      this.dayTimesStore.getDayTimes();
      this.toast.success("Tageszeit erfolgreich angelegt!")
      this.dialogCreate = false;
    },

    createItem() {
      this.dialogCreate = true;
    },

    closeCreateDialog() {
      this.dialogCreate = false;
    },

    closeDialogEdit() {
      this.dialogEdit = false;
    },
  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

.colored-dot {
  width: 30px;
  height: 30px;
  border-radius: 25%; /* Rund machen, um einen Punkt zu erstellen */
  display: inline-block;
}

</style>

