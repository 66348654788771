import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const useContractStore = defineStore('ContractStore', {

    state: () => {

        const contractData: any = {};
        const productsByContract: any[] = [];
        const paymentMethodByContract: any[] = [];

        return {
            contractData,
            productsByContract,
            paymentMethodByContract
        }

    },
    actions: {

        async getContractByCustomerId(customerId: number) {

            const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/contract/customer/' + customerId, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.contractData = data.data[0];


        },

        async getContractProductsByContractId(contractId: number) {

            const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/contract/' + contractId + '/products', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.productsByContract = data.data;
        },
    },
    persist: false,
});