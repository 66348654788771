import {defineStore} from "pinia";
// @ts-ignore
import {UserModel} from "@/models/user.model";
import axiosInstance from "@/plugins/axios";
import {API_URL, findSupportUserRole} from "@/services/Helper.service";
import keycloak from "@/plugins/keycloak";
import {RightsResponseModel} from "@/models/rights.response.model";

export const useUserStore = defineStore("UserStore", {
    state: () => {
        const user: UserModel = {token: undefined, keycloakUUID: undefined};
        const userListData: any = [];
        const backofficeRights: Array<RightsResponseModel> = [];
        return {
            user, backofficeRights,
            userListData
        };
    },
    actions: {
        async authenticateUser() {
            const authenticated = await keycloak.init({
                onLoad: "login-required",
                checkLoginIframe: false,
                redirectUri: window.origin + "/",

            });
            if (keycloak.token) {
                await this.scheduleTokenRefresh(keycloak);
                this.user.keycloakUUID = keycloak.subject;
                await this.loadUserDataByKeyCloakUUID();
                this.user.token = keycloak.token;

                return authenticated;
            }
            return authenticated;
        },
        async checkRightsForApp() {
            if (this.user.token && keycloak.tokenParsed) {
                const index = await findSupportUserRole(keycloak.tokenParsed);
                return index !== -1;
            }
        },

        async updateUser(userId: any, userData: any) {
            const userStore = useUserStore();

            const data = await axiosInstance.put(API_URL + '/api/user/' + userId, userData, {
                headers: {
                    'Authorization': `Bearer ${userStore.user.token}`
                },
            });
        },

        async logoutUser() {
            await keycloak.logout();
        },

        async getEmployeeList() {
            const userStore = useUserStore();
            const userData = await axiosInstance.get(API_URL + '/api/user/', {
                headers: {
                    'Authorization': `Bearer ${userStore.user.token}`
                }
            })
            this.userListData = userData.data
        },

        async loadUserDataByKeyCloakUUID(){


            const data = await axiosInstance.get(API_URL + '/api/user/keycloak/' + this.user.keycloakUUID, {
                headers: {
                    'Authorization': `token ${this.user.token}`
                }
            })
            this.user = data.data[0];

        },

        async scheduleTokenRefresh(keycloak: any) {
            setTimeout(async () => {
                if (keycloak.tokenParsed && keycloak.tokenParsed.exp) {
                    try {
                        const refreshed = await keycloak.updateToken(30);
                        if (refreshed) {
                            this.user.token = keycloak.token;
                            await this.scheduleTokenRefresh(keycloak);
                        }
                    } catch (err) {
                        console.error("Fehler beim Token-Refresh", err);
                    }
                } else {
                    console.error("Token oder Tokenablaufinformationen fehlen.");
                }
            }, (keycloak.tokenParsed?.exp || 0) * 1000 - Date.now() - 10000);
        },
        async getBackofficeRights(): Promise<Array<RightsResponseModel>> {
            if (this.backofficeRights.length === 0) {
                try {
                    const rightsData = await axiosInstance.get(API_URL + '/api/user/rights/' + this.user.keycloakUUID, {
                        headers: {
                            'Authorization': `Bearer ${this.user.token}`
                        }
                    });
                    this.backofficeRights.push(... rightsData.data);
                    return this.backofficeRights;
                } catch (error) {
                    console.error('Error fetching backoffice EmployeeRights:', error);
                    throw error;
                }
            } else {
                return this.backofficeRights;
            }
        },
    },
    getters: {},
    persist: false,
});
