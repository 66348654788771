<template>
  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="updateItemConfirm">

      <v-card>
        <v-card-title>
          <div class="d-flex flex-row justify-start w-100">
            <h3>Tageszeit aktualisieren</h3>
          </div>

        </v-card-title>

        <v-card-text>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-space-evenly">
              <div class="d-flex align-center justify-center w-25">
                <v-color-picker
                    v-model="getEditItem.color"
                    mode="hex"/>
              </div>
              <div class="d-flex flex-column align-center justify-center w-25">
                <div class="d-flex flex-column w-100">
                  <v-label for="position">Beschreibung</v-label>
                  <v-text-field id="position" v-model="getEditItem.description" type="text"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex flex-column align-center justify-center w-25">

                <VueDatePicker id="datepickerTo" class=""
                               v-model="getEditItem.start_time"
                               :time-picker="true"
                               locale="de"
                               :inline="true"
                               text-input auto-apply
                               format="HH:mm"
                />
              </div>
              <div class="d-flex flex-column align-center justify-center w-25">
                <VueDatePicker id="datepickerTo" class=""
                               v-model="getEditItem.end_time"
                               :time-picker="true"
                               locale="de"
                               :inline="true"
                               text-input auto-apply
                               format="HH:mm"
                />
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeEdit">Abbrechen</button>
            <div class="ma-2"></div>
            <button class="button-standard" type="submit">Tageszeit aktualisieren</button>
          </div>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import {useMaskStore} from "@/stores/MaskStore";
import VueDatePicker from "@vuepic/vue-datepicker";
import {de} from "date-fns/locale";

export default {

  setup() {
    const packageModelStore = useClientModelStore();
    const toast = useToast();
    const maskStore = useMaskStore();
    return {packageModelStore, toast, maskStore}
  },

  async mounted() {
    await this.maskStore.getMasks();
  },

  methods: {
    closeEdit(e) {
      this.$emit("close-edit-dialog");
      e.preventDefault();
    },

    async updateItemConfirm() {
      const isFormValid = await this.$refs.form.validate();

      if (isFormValid.valid) {
        this.$emit("update-dialog-item", this.getEditItem);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    },
    parseTimeString(timeString) {
      const parts = timeString.split(':');
      return {
        hours: parseInt(parts[0], 10),
        minutes: parseInt(parts[1], 10)
      };
    },
  },

  data() {
    return {}
  },

  components: {VueDatePicker},

  props: {
    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    }
  },
  computed: {
    de() {
      return de
    },

    isDialogOpen() {
      return this.dialogOpen;
    },

    getEditItem: {
      get() {
        const item = this.editItem;
        if (item.start_time && typeof item.start_time !== 'object') {
          item.start_time = this.parseTimeString(item.start_time);
        }
        if (item.end_time && typeof item.end_time !== 'object') {
          item.end_time = this.parseTimeString(item.end_time);
        }
        return item;
      },
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
