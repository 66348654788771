<template>

  <abstract-data-table

      :is-loading="isLoading"
      title=""
      :items="priceModelStore.priceModelData"
      :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>

      <div class="ma-2"/>

      <button class="table-header-action-button" v-if="hasEditRights" @click="createItem">Preismodell anlegen</button>
    </template>

    <template #item.actions="{ item }">
      <div class="d-flex flex-row w-100 justify-center">
        <router-link v-if="hasEditRights" class="table-data-action-button" title="Bearbeiten"
                     :to="'/preismodell-bearbeiten/' + item.id">
          <v-icon>mdi-pencil</v-icon>
        </router-link>
        <button v-if="hasEditRights" class="table-data-action-button" @click="deleteItem(item.id)" title="Löschen">
          <v-icon>mdi-trash-can</v-icon>
        </button>
      </div>
    </template>


    <template #item.active=" { item } ">
        <ColoredDot :is-active="item.active"/>
    </template>

    <template #item.valid_from=" { item } ">
      {{ formatDate(item.valid_from )}}
    </template>

    <template #item.valid_to=" { item } ">
      {{ formatDate(item.valid_to )}}
    </template>

    <template #item.price_model_type_id=" { item } ">
      {{ translatePriceModelItemTypeIdToString(item.price_model_type_id )}}
    </template>


  </abstract-data-table>

  <ConfirmDeleteDialog title="Bist du sicher das du dieses Preismodell löschen möchtest?"
                       v-model:dialog-open="dialogDelete" @close-delete-dialog="dialogDeleteActionClose"
                       @delete-dialog-item="dialogConfirmDeleteItem"/>

  <CreatePriceModelDialog v-model:dialog-open="dialogCreate" @close-create-dialog="dialogCreateActionClose"
                          @confirm-create-dialog="dialogConfirmCreateItem"/>

</template>

<script>
import {usePriceModelStore} from "@/stores/PriceModelStore";
import {inject} from "vue";
import ConfirmDeleteDialog from "@/components/table/ConfirmDeleteDialog.vue";
import {useToast} from "vue-toastification";
import CreatePriceModelDialog from "@/components/table/priceModel/CreatePriceModelDialog.vue";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import ColoredDot from "@/components/table/ColoredDot.vue";
import {formatDate, hasEditRightsForMask} from "@/services/Helper.service";
import {useMaskStore} from "@/stores/MaskStore";

export default {
  components: {
    ColoredDot,
    AbstractDataTable,
    CreatePriceModelDialog,
    ConfirmDeleteDialog,
  },

  setup() {

    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const maskStore = useMaskStore();
    const toast = useToast();
    const moment = inject('$moment')
    return {priceModelStore, moment, toast, priceModelTypeStore, maskStore};

  },

  async mounted() {
    this.isLoading = true;
    this.hasEditRights = await hasEditRightsForMask(this.backofficeMask);
    await this.priceModelStore.getPriceModels();
    await this.maskStore.getMasks();
    await this.priceModelTypeStore.getPriceModelTypes();
    this.isLoading = false;

  },

  data() {
    return {
      isLoading: true,
      dialogDelete: false,
      backofficeMask: 'Preismodelle',
      hasEditRights: false,
      dialogCreate: false,
      editedItem: {
        id: 0,
      },
      headers: [

        {title: 'ID', align: 'center', key: 'id', sortable: true},
        {title: 'Typ', align: 'center', key: 'price_model_type_id', sortable: false},
        {title: 'Gültig von', align: 'center', key: 'valid_from', sortable: false},
        {title: 'Gültig bis', align: 'center', key: 'valid_to', sortable: false},
        {title: 'Status', align: 'center', key: 'active', sortable: false},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},

      ],
    }
  },

  methods: {
    formatDate,

    async refreshTable() {
      await this.priceModelStore.getPriceModels();
      this.toast.success("Tabelle aktualisiert");
    },

    async deleteItem(id) {
      this.editedItem.id = id;
      this.dialogDelete = true;
    },
    dialogDeleteActionClose() {
      this.dialogDelete = false;
    },
    async dialogConfirmDeleteItem() {
      if (this.editedItem) {
        await this.priceModelStore.deleteSinglePriceModel(this.editedItem.id)
        await this.priceModelStore.getPriceModels();
        this.dialogDeleteActionClose();
        this.toast.success("Preismodell erfolgreich gelöscht!")
      } else {
        throw Error("Kein Item zum editieren ausgewählt")
      }
    },

    createItem() {
      this.dialogCreate = true;
    },

    dialogCreateActionClose() {
      this.dialogCreate = false;
    },

    async dialogConfirmCreateItem(priceModel) {
      await this.priceModelStore.createSinglePriceModel(priceModel);
      await this.priceModelStore.getPriceModels();
      this.toast.success("Preismodell erfolgreich angelegt")
      this.dialogCreate = false;
    },
    translatePriceModelItemTypeIdToString(priceModelTypeId) {

      if (this.priceModelTypeStore.priceModelTypeData.length > 0) {

        const priceModelTypeObject = this.priceModelTypeStore.priceModelTypeData.find(function (obj) {
          if (Number(obj.id) === Number(priceModelTypeId)) {
            return obj;
          }
        });
        return priceModelTypeObject.name
      }
    }
  },
}


</script>

<style scoped>

.table-container {
  border: 1px solid var(--border-color);
  max-height: 100vh;
  overflow-y: auto;
}

td {
  color: var(--table-data-text-color);
  font-family: var(--main-font-family);
}

th {
  color: #607081;
}


.even-row {
  background-color: #F9FAFB; /* Even row color */
}

.odd-row {
  background-color: white; /* Odd row color */
}

.no-data-container {
  background-color: white;
}

</style>
