<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <DayTimesTable/>

    </div>
  </div>
</template>


<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";
import {useToast} from "vue-toastification";
import DayTimesTable from "@/components/table/dayTimes/DayTimesTable.vue";
import {useDayTimesStore} from "@/stores/DayTimesStore";

export default {
  components: {DayTimesTable},
  computed: {
    auth() {
      return auth
    }
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();
    const toast = useToast();
    const dayTimesStore = useDayTimesStore();
    return {pageHeaderStore, dayTimesStore, toast};
  },

  data() {
    return {
      icdTenFile: null,
    };
  },

  async mounted() {
    const breadcrumbs = [
      {title: 'Tageszeiten'},
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    // Actions setzen
    const actions = [
      {label: 'Speichern', class: 'button-standard', onClick: this.saveDayTimes},
    ];

    this.pageHeaderStore.setActions(actions);

  },

  methods: {
    onFileSelectedProductDescription(event) {
      this.icdTenFile = event.target.files[0];
    },

    async saveDayTimes() {
        await this.dayTimesStore.updateDayTimes();
        this.toast.success("Tageszeiten erfolgreich aktualisiert")
    },
  },
}

</script>


<style scoped>

.even-flex-basis {
  flex-basis: 50%;
}

.datepicker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-card {
  margin-top: 32px;
  margin-bottom: 32px;
}

.v-card-text {
  padding: 0;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.v-card-text {
  padding: 20px;
}

</style>
