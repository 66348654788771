<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <v-card class="flex-grow-1">

        <v-card-title>QR-Code generieren</v-card-title>

        <v-card-text>

          <div class="d-flex flex-column w-100">
            <p class="b1 mb-2">Kundennummer</p>
            <div class="d-flex flex-column">
              <v-text-field v-model="customerNumber" type="text"
              ></v-text-field>
            </div>
            <p class="b1 mb-2">QR-Code</p>
            <v-divider/>
            <div class="qr-code-container">

              <div v-if="isLoading" class="d-flex flex-row justify-center">
                <span class="spinner"></span>
              </div>

              <div v-else>
                <div v-if="qrCodeStore.qrData">
                  <img :src="qrCodeStore.qrData" alt="QR Code" />
                </div>
                <div v-else>
                  QR-Code Platzhalter
                </div>
              </div>

            </div>
          </div>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>


<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";
import {useToast} from "vue-toastification";
import {useQrCodeStore} from "@/stores/QrCodeStore";

export default {
  components: {},
  computed: {
    auth() {
      return auth
    }
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();
    const toast = useToast();
    const qrCodeStore = useQrCodeStore();
    return {pageHeaderStore, qrCodeStore, toast};
  },

  data() {
    return {
      customerNumber: null,
      isLoading: false,
    };
  },

  async mounted() {
    const breadcrumbs = [
      {title: 'QR-Code'},
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    // Actions setzen
    const actions = [
      {label: 'QR-Code Generieren', class: 'button-standard', onClick: this.generateQrCode},
    ];

    this.pageHeaderStore.setActions(actions);

    this.qrCodeStore.qrData = null;

  },

  methods: {
    async generateQrCode() {

      if (this.customerNumber === null || this.customerNumber === '') {
        this.toast.error('Bitte gebe eine Kundennummer ein');
        return;
      }

      this.isLoading = true;
      await this.qrCodeStore.getQrCode(this.customerNumber);
      this.isLoading = false;

      if (this.qrCodeStore.qrData !== null) {
        this.toast.success('QR-Code wurde erfolgreich generiert');
      } else {
        this.toast.error('QR-Code konnte nicht generiert werden');
      }
    },
  },
}

</script>


<style scoped>


.v-card {
  margin-top: 32px;
  margin-bottom: 32px;
}

.v-card-text {
  padding: 0;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.v-card-text {
  padding: 20px;
}

.qr-code-container {
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

</style>
