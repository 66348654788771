import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import {useTargetGroupStore} from "@/stores/TargetGroupStore";
import {API_URL} from "@/services/Helper.service";

export const useClientModelStore = defineStore('ClientModelStore', {

        state: () => {

            const clientModelData: any[] = [];
            const clientModelDataByPriceModelData: any[] = [];

            return {
                clientModelData,
                clientModelDataByPriceModelData
            }

        },
        actions: {

            async getClientModels() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/clientmodel/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.clientModelData = data.data;
            },

            async getClientModelsByPriceModelId(id: any) {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/clientmodel/pricemodel/' + id, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.clientModelDataByPriceModelData = data.data;

            },

            async updateSingleClientModel(idToUpdate: any, elementToUpdate: any) {
                const userStore = useUserStore();
                const data = await axiosInstance.put(API_URL + '/api/clientmodel/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },

            async createSingleClientModel(elementToCreate: any) {
                const userStore = useUserStore();

                elementToCreate.price_model_id = Number(elementToCreate.price_model_id);

                const data = await axiosInstance.post(API_URL + '/api/clientmodel', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },

            async deleteSingleClientModel(idToDelete: any) {

                const userStore = useUserStore();
                const data = await axiosInstance.delete(API_URL + '/api/clientmodel/' + idToDelete, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },
        },
        getters: {

            translateTargetGroupIdToText: (state) => (targetGroupId: any) => {

                const targetGroupStore = useTargetGroupStore();
                if (targetGroupStore.targetGroupData.length > 0) {

                    const targetGroupObj = targetGroupStore.targetGroupData.find(function (obj) {
                        if (Number(obj.id) === Number(targetGroupId)) {
                            return obj;
                        }
                    });
                    return targetGroupObj.name
                }

            }
        },

        persist: false,
    })
;