<template>
  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="updateItemConfirm">

      <v-card>
        <v-card-title>
          <div class="d-flex flex-row align-center justify-space-between">

            <div class="d-flex flex-row justify-start">
              <h3>Kunden & Vertragsdaten</h3>
            </div>

            <div class="d-flex flex-grow-1 justify-center">
              <v-tabs
                  v-model="tab"
              >
                <v-tab :value="1">Kundendaten</v-tab>
                <v-tab :value="2">Vertragsdaten</v-tab>
                <v-tab :value="3">Produkte</v-tab>
              </v-tabs>
            </div>

            <!-- Unsichtbarer Platzhalter -->
            <div style="visibility: hidden;">
              <h3>Kunden & Vertragsdaten</h3>
            </div>
          </div>

        </v-card-title>

        <v-card-text>

          <v-window v-model="tab">
            <v-window-item :value="1">

              <div class="d-flex flex-column">

                <div class="d-flex flex-row">

                  <v-card-item class="w-50">
                    <div class="d-flex flex-column">
                      <v-label for="customer_number">Kundennummer</v-label>
                      <v-text-field placeholder="Nicht gesetzt" id="customer_number"
                                    v-model="getEditItem.customer_number" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-50">
                    <div class="d-flex flex-column">
                      <v-label for="customer_number">One ID</v-label>
                      <v-text-field placeholder="Nicht gesetzt" :readonly="true" id="customer_number"
                                    v-model="getEditItem.one_id" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>
                </div>

                <v-divider/>

                <div class="d-flex flex-row">

                  <v-card-item class="w-100">
                    <div class="d-flex flex-column">
                      <v-label for="position">Unternehmen</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.company" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                </div>

                <div class="d-flex flex-row">

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Straße</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.street" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Hausnummer</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.house_number" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Plz</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.zip_code" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Ort</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.place" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                </div>

                <div class="d-flex flex-row">

                  <v-card-item class="w-50">
                    <div class="d-flex flex-column">
                      <v-label for="position">Vorname</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.first_name" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-50">
                    <div class="d-flex flex-column">
                      <v-label for="position">Nachname</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.last_name" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                </div>

                <div class="d-flex flex-row">

                  <v-card-item class="w-33">
                    <div class="d-flex flex-column">
                      <v-label for="position">E-Mail (Rechnungsadresse)</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.email_invoice" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-33">
                    <div class="d-flex flex-column">
                      <v-label for="position">Telefon</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.phone" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-33">
                    <div class="d-flex flex-column">
                      <v-label for="position">Fax</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.fax" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                </div>

                <div class="d-flex flex-row">

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Ik</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.ik" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Verbandsmitglied</v-label>
                      <v-text-field :readonly="true" id="position" v-model="getEditItem.club_member" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Ik-file</v-label>

                      <button @click="customerStore.openCustomerFile(getEditItem.id, 'ik')"
                              class="h-100 button-standard"
                              type="submit">Datei abrufen
                      </button>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Verbandsmitglied Datei</v-label>
                      <button @click="customerStore.openCustomerFile(getEditItem.id, 'membership')"
                              class="h-100 button-standard"
                              type="submit">Datei abrufen
                      </button>
                    </div>
                  </v-card-item>

                </div>
              </div>

            </v-window-item>

            <v-window-item :value="2">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row">

                </div>
                <div class="d-flex flex-row">

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Datum Erstbestellung</v-label>
                      <v-text-field :readonly="true" id="position" :value="formatDate(getEditItem.createdAt, true, 'LL')"
                                    type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Datum Erstbuchung</v-label>
                      <v-text-field :readonly="true" id="position" :value="formatDate(getEditItem.createdAt, true, 'LL')" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Erster Vertragsbeginn</v-label>
                      <v-text-field :readonly="true" id="position"
                                    :value="formatDate(contractStore.contractData.date_from, true, 'LL')" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Nutzungsvereinbarung</v-label>

                      <button @click="customerStore.openCustomerFile(getEditItem.id, 'user_agreement')"
                              class="h-100 button-standard"
                              type="submit">Datei abrufen
                      </button>
                    </div>
                  </v-card-item>

                </div>
                <div class="d-flex flex-row">

                  <v-card-item class="w-100">
                    <div class="d-flex flex-column">
                      <v-label for="position">Bezahlmethode</v-label>
                      <v-text-field :readonly="true" id="position"
                                    :value="paymentMethodTypeStore.translatePaymentMethodIdToText(contractStore.contractData.payment_method.payment_method_type_id)"
                                    type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                </div>

                <v-divider/>

                <div v-if="contractStore.contractData.payment_method.payment_method_type_id === 1" class="d-flex flex-row">

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Kontoinhaber</v-label>

                      <v-text-field :readonly="true" id="position"
                                    :value="contractStore.contractData.payment_method.account_holder" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Adresse</v-label>

                      <v-text-field :readonly="true" id="position"
                                    :value="contractStore.contractData.payment_method.address" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">BIC</v-label>

                      <v-text-field :readonly="true" id="position"
                                    :value="contractStore.contractData.payment_method.bic" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>

                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">IBAN</v-label>

                      <v-text-field :readonly="true" id="position"
                                    :value="contractStore.contractData.payment_method.iban" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>


                </div>

                <div v-else class="d-flex flex-row">
                  <v-card-item class="w-25">
                    <div class="d-flex flex-column">
                      <v-label for="position">Kundennummer</v-label>

                      <v-text-field :readonly="true" id="position"
                                    :value="contractStore.contractData.payment_method.customer_number" type="text"
                      ></v-text-field>
                    </div>
                  </v-card-item>
                </div>

              </div>
            </v-window-item>

            <v-window-item :value="3">
              <abstract-data-table

                  title=""
                  :items="contractStore.productsByContract"
                  :headers="headers"
              >
                <template #item.actions="{ item }">
                  <button @click="editItem(item)" class="table-data-action-button" title="Bearbeiten">
                    <v-icon>mdi-pencil</v-icon>
                  </button>
                </template>

                <template #item.createdAt="{ item }">
                  {{formatDate(item.createdAt)}}
                </template>

                <template #item.price_per_month="{ item }">
                  {{convertToGermanCurrency(item.price_per_month)}}
                </template>

                <template #no-data>
                  <p>Keine Produkte verfügbar</p>
                </template>
              </abstract-data-table>
            </v-window-item>
          </v-window>

        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeEdit">Abbrechen</button>
            <div class="ma-2"></div>
            <button class="button-standard" type="submit">Kundendaten aktualisieren</button>
          </div>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useContractStore} from "@/stores/ContractStore";
import {useCustomerStore} from "@/stores/CustomerStore";
import {convertToGermanCurrency, formatDate} from "@/services/Helper.service";
import {usePaymentMethodTypeStore} from "@/stores/PaymentMethodTypeStore";

export default {
  components: {AbstractDataTable},

  setup() {
    const packageModelStore = useClientModelStore();
    const customerStore = useCustomerStore();
    const paymentMethodTypeStore = usePaymentMethodTypeStore();
    const contractStore = useContractStore();
    const toast = useToast();
    return {packageModelStore, toast, contractStore, customerStore, paymentMethodTypeStore}
  },

  methods: {
    convertToGermanCurrency,
    formatDate,

    async loadData() {
      await this.contractStore.getContractByCustomerId(this.getEditItem.id);
      await this.contractStore.getContractProductsByContractId(this.contractStore.contractData.id);
      await this.paymentMethodTypeStore.getPaymentMethodTypes();
    },

    closeEdit(e) {
      this.$emit("close-edit-dialog");
      e.preventDefault();
    },

    async updateItemConfirm() {
      const isFormValid = await this.$refs.form.validate();

      if (isFormValid.valid) {

        const updateItem = {
          id: this.getEditItem.id,
          customer_number: this.getEditItem.customer_number,
        }

        this.$emit("update-dialog-item", updateItem);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    }
  },

  watch: {
    dialogOpen(newVal) {
      if (newVal) {
        // Daten laden, wenn der Dialog geöffnet wird
        this.loadData();
      }
    }
  },

  data() {
    return {
      editor: ClassicEditor,
      tab: 1,

      headers: [
        {title: 'Positionsnummer', align: 'center', key: 'position', sortable: true},
        {title: 'Bezeichnung', align: 'center', key: 'name', sortable: true},
        {title: 'Preis pro Monat', align: 'center', key: 'price_per_month', sortable: true},
        {title: 'Gebucht Datum', align: 'center', key: 'createdAt', sortable: true},
        {title: 'Vertragsbeginn Datum', align: 'center', key: '', sortable: true},
        {title: 'Vertragsende Datum', align: 'center', key: 'empty', sortable: true},

      ],
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    }
  },
  computed: {

    isDialogOpen() {
      return this.dialogOpen;
    },
    getEditItem() {
      return this.editItem;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
