<template>
  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="updateItemConfirm">

      <v-card>
        <v-card-title>

          <div class="d-flex flex-row justify-start w-100">
            <h3>Produkt aktualisieren</h3>
          </div>

        </v-card-title>

        <v-card-text>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="position">Position</v-label>
                  <v-text-field :rules="positionRule" id="position" v-model="getEditItem.position" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="price_unique">Preis einmalig</v-label>
                  <v-text-field :rules="priceUniqueRule" id="price_unique" v-model="getEditItem.price_unique" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="price_per_month">Preis monatlich</v-label>
                  <v-text-field :rules="pricePerMonthRule" id="price_per_month" v-model="getEditItem.price_per_month"
                                type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">
              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="price_saving">Preis Ersparnis</v-label>
                  <v-text-field :rules="priceSavingRule" id="price_saving" v-model="getEditItem.price_saving"
                                type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="price_deviation">Preis Abweichung (Monatlich)</v-label>
                  <v-text-field :rules="priceDeviationRule" id="price_deviation" v-model="getEditItem.price_deviation"
                                type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="price_deviation_months">Preis Abweichung (Monatlich), Anzahl Monate</v-label>
                  <v-text-field :rules="priceDeviationRule" id="price_deviation_months" v-model="getEditItem.price_deviation_months"
                                type="number"
                  ></v-text-field>
                </div>
              </v-card-item>
              </div>

            <div v-if="getEditItem.mask_id <= 2" class="d-flex flex-row">
              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Module</v-label>
                  <ckeditor :editor="editor" id="description_module" v-model="getEditItem.description_module"/>
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Lizenzen</v-label>
                  <ckeditor :editor="editor" id="getEditItem.description_licence"
                            v-model="getEditItem.description_licence"
                            />
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Service</v-label>
                  <ckeditor :editor="editor" id="getEditItem.description_service"
                            v-model="getEditItem.description_service"
                            />
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Laufzeit</v-label>
                  <ckeditor :editor="editor" id="getEditItem.description_runtime"
                            v-model="getEditItem.description_runtime"
                            />
                </div>
              </v-card-item>
            </div>

            <div v-if="getEditItem.mask_id > 2" class="d-flex flex-row">
              <v-card-item class="w-100">
                <div class="d-flex flex-column">
                  <v-label for="description">Beschreibung</v-label>
                  <ckeditor :editor="editor" id="description" v-model="getEditItem.description"
                            />
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="name">Bezeichnung</v-label>
                  <v-text-field :rules="nameRule" id="name" v-model="getEditItem.name" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="allow_order_amount_input">Erlaube Eingabe Bestellmenge</v-label>

                  <v-select id="allow_order_amount_input" :items="activeOrInactive"
                            v-model="getEditItem.allow_order_amount_input"></v-select>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="order">Reihenfolge</v-label>
                  <v-text-field :rules="orderRule" id="order" v-model="getEditItem.order" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">
              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="bestseller">Bestseller</v-label>
                  <v-select id="bestseller" :items="activeOrInactive"
                            v-model="getEditItem.bestseller"></v-select>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
              <div class="d-flex flex-column">
                <v-label for="active">Aktiv</v-label>
                <v-select id="active" :items="activeOrInactive"
                          v-model="getEditItem.active"></v-select>
              </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="mask_id">Maske</v-label>
                  <v-select id="mask_id" item-title="name" :items="maskStore.getMaskByPriceModelTypeId(2)" item-value="id"
                            v-model="getEditItem.mask_id" type="text"
                  ></v-select>
                </div>
              </v-card-item>

            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeEdit">ABBRECHEN</button>
            <div class="ma-2"></div>
            <button class="button-standard" type="submit">Produkt aktualisieren</button>
          </div>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import {useMaskStore} from "@/stores/MaskStore";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue";
export default {

  setup() {
    const packageModelStore = useClientModelStore();
    const toast = useToast();
    const maskStore = useMaskStore();
    return {packageModelStore, toast, maskStore}
  },

  async mounted() {
    await this.maskStore.getMasks();
  },

  components: {
    ckeditor: CKEditor.component
  },

  methods: {
    closeEdit(e) {
      this.$emit("close-edit-dialog");
      e.preventDefault();
    },

    async updateItemConfirm() {
      const isFormValid = await this.$refs.form.validate();

      if (isFormValid.valid) {
        this.$emit("update-dialog-item", this.getEditItem);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    }
  },

  data() {
    return {
      editor: ClassicEditor,

      activeOrInactive: [{title: 'Aktiv', value: true}, {title: 'Inaktiv', value: false}],

      positionRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[A-Za-z0-9]{3,6}$/;
          if (!regex.test(v)) {
            return 'Der Name muss alphanumerisch sein und aus 3 bis 6 Zeichen bestehen';
          }
          return true;
        }
      ],
      priceUniqueRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }
          return true;
        }
      ],
      pricePerMonthRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }
          return true;
        }
      ],
      priceSavingRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }
          return true;
        }
      ],
      priceDeviationRule: [
        v => {
          if (v === '') return true;  // Erlaubt leere Eingabe

          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }

          return true;
        }
      ],
      nameRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^.{1,150}$/;
          if (!regex.test(v)) {
            return 'Der eingegebene Text überschreitet die maximale Zeichenlänge von 150 Zeichen. Bitte kürzen Sie den Text auf maximal 500 Zeichen.';
          }
          return true;
        }
      ],
      descriptionRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^.{1,500}$/;
          if (!regex.test(v)) {
            return 'Der eingegebene Text überschreitet die maximale Zeichenlänge von 500 Zeichen. Bitte kürzen Sie den Text auf maximal 500 Zeichen.';
          }
          return true;
        }
      ],
      orderRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^(?:[1-9]|[1-9][0-9])$/;
          if (!regex.test(v)) {
            return 'Die eingegebene Zahl ist ungültig. Bitte geben Sie eine zweistellige Zahl im Bereich von 1 bis 99 ein.';
          }
          return true;
        }
      ],
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    }
  },
  computed: {
    ClassicEditor() {
      return ClassicEditor
    },
    isDialogOpen() {
      return this.dialogOpen;
    },
    getEditItem() {
      return this.editItem;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
