<template>

  <div class="page-container">
    <div class="d-flex flex-column">


      <BusinessTypeTable/>

    </div>
  </div>

  <EditMaskDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                  @close-edit-dialog="closeDialogEdit"
                  @update-dialog-item="updateDialogItem"/>

</template>

<script>
import {usePriceModelStore} from "@/stores/PriceModelStore";
import EditMaskDialog from "@/components/table/mask/EditMaskDialog.vue";
import {useToast} from "vue-toastification";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";
import BusinessTypeTable from "@/components/table/businessType/BusinessTypeTable.vue";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {
  components: {
    BusinessTypeTable,
    EditMaskDialog,
  },

  setup() {

    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const pageHeaderStore = usePageHeaderStore();
    const businessTypeStore = useBusinessTypeStore();
    const toast = useToast();
    return {priceModelStore, toast, priceModelTypeStore, businessTypeStore,pageHeaderStore};

  },

  async mounted() {

    const breadcrumbs = [
      { title: 'Unternehmensformen' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    await this.businessTypeStore.getBusinessTypes();

  },

  data() {
    return {
      dialogEdit: false,
      isLoading: true,
      activePanel: 0,
      editedItem: {
        id: 0,
        name: "",
        description: "",
        price_model_type_id: 0
      },
    }
  },

  methods: {

    async panelChanged(panelIndex) {
      this.isLoading = true;

      await this.$nextTick();

      if (this.activePanel === panelIndex) {
        this.isLoading = false;
      }
    },

    contentLoaded() {
      this.isLoading = false;
    },

    async updateDialogItem(editItemFromDialog) {
      await this.maskStore.updateSingleMask(editItemFromDialog.id, editItemFromDialog);
      await this.maskStore.getMasks();
      this.toast.success("Maske erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    closeDialogEdit() {
      this.dialogEdit = false;
    },

    editItem(item) {

      console.log("item", item);

      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    async refreshTable() {
      await this.maskStore.getMasks();
      this.toast.success("Tabelle aktualisiert");
    },

  },
}


</script>

<style scoped>

.table-container {
  border: 1px solid var(--border-color);
  max-height: 100vh;
  overflow-y: auto;
}

td {
  color: var(--table-data-text-color);
  font-family: var(--main-font-family);
}

th {
  color: #607081;
}

.even-row {
  background-color: #F9FAFB; /* Even row color */
}

.odd-row {
  background-color: white; /* Odd row color */
}

.no-data-container {
  background-color: white;
}

</style>