<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <PageTitle title="Mitarbeiter"/>
      <EmployeeRightsTable/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import EmployeeRightsTable from "@/components/table/EmployeeRights/EmployeeRightsTable.vue";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {
  components: {
    EmployeeRightsTable,
    PageTitle
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();

    return {pageHeaderStore};
  },


  mounted() {

    const breadcrumbs = [
      { title: 'Mitarbeiter' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

  }
}
</script>

<style scoped>
.table-container {
  border     : 1px solid var(--border-color);
  max-height : 100vh;
  overflow-y : auto;
}

td {
  color       : var(--table-data-text-color);
  font-family : var(--main-font-family);
}

th {
  color : #607081;
}

.even-row {
  background-color : #f9fafb; /* Even row color */
}

.odd-row {
  background-color : white; /* Odd row color */
}

.no-data-container {
  background-color : white;
}
</style>
