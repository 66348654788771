<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <v-card class="flex-grow-1 overflow-visible">

        <v-card-title>Preismodell - Konfiguration</v-card-title>

        <v-card-text>

          <div class="d-flex flex-column">
            <div class="d-flex flex-row mt-3 mb-3 card-item">
              <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column w-50 mr-2">
                  <label for="selectPriceModel" class="poppins">Preismodell:</label>
                  <v-select id="selectPriceModel" class="even-flex-basis" :readonly="true" variant="outlined"
                            density="compact"
                            hide-details="auto"
                            item-title="name"
                            item-value="id"
                            :items="priceModelTypeStore.priceModelTypeData"
                            v-model="editPriceModelData.price_model_type_id"
                  />
                </div>

                <div class="d-flex flex-column w-50 ml-2">
                  <label for="selectIsPriceModelActive" class="poppins">Aktiv:</label>
                  <v-select id="selectIsPriceModelActive" class="even-flex-basis" variant="outlined"
                            density="compact"
                            hide-details="auto"
                            v-model="editPriceModelData.active"
                            :items="[{title: 'Aktiv', value: true}, {title: 'Inaktiv', value: false}]"
                  />
                </div>
              </div>
            </div>

            <div class="d-flex flex-row w-95 ml-5 mr-5">
              <div class="d-flex flex-column w-25 pr-3">
                <p class="b1 mb-2">Produktbeschreibung</p>
                  <v-file-input class="flex-grow-1" accept=".pdf" @change="onFileSelectedProductDescription"/>
              </div>

              <div class="d-flex flex-column w-25 pr-3">
                <p class="b1 mb-2">AGBs</p>
                <v-file-input class="w-100" accept=".pdf" @change="onFileSelectedAGB"/>
              </div>

              <div class="d-flex flex-column w-25 pr-3">
                <p class="b1 mb-2">Auftragsverarbeitungsvertrag</p>
                <v-file-input class="w-100" accept=".pdf" @change="onFileSelectedOrderProcessingContract"/>
              </div>

              <div class="d-flex flex-column w-25 pr-3">
                <p class="b1 mb-2">Transparenzkriterien</p>
                <v-file-input class="w-100" accept=".pdf" @change="onFileSelectedTransparency"/>
              </div>

            </div>


            <div class="d-flex flex-row card-item">
              <div class="datepicker-container mr-3">
                <label for="datepickerFrom" class="poppins">Aktiv von:</label>

                <VueDatePicker id="datepickerFrom" class="foreground"
                               v-model="editPriceModelData.valid_from"
                               local="de"
                               format="dd.MM.yyyy HH:mm"
                               select-text="Speichern"
                               cancel-text="Abbrechen"/>
              </div>

              <div class="datepicker-container ml-3">
                <label for="datepickerFrom" class="poppins">Aktiv bis:</label>
                <VueDatePicker id="datepickerTo" class="even-flex-basis"
                               v-model="editPriceModelData.valid_to"
                               locale="de"
                               format="dd.MM.yyyy HH:mm"
                               select-text="Speichern"
                               cancel-text="Abbrechen"/>
              </div>
            </div>
          </div>

        </v-card-text>

      </v-card>

      <div class="">

        <ClientProductsTable v-if="editPriceModelData.price_model_type_id === 1"
                             :price-model-id="this.priceModelId"/>
        <PackageProductsTable v-if="editPriceModelData.price_model_type_id === 2"
                              :price-model-id="this.priceModelId"/>

      </div>

      <v-card>
        <v-card-title>Vertragsdaten</v-card-title>

        <v-card-text>
          <div class="d-flex flex-column">
            <h3 class="mb-3">Vertragstext</h3>
            <ckeditor :editor="editor" v-model="editedItem.contract_page_3_text"/>
          </div>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {de} from 'date-fns/locale';
import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import {useRoute, useRouter} from "vue-router";
import {usePriceModelStore} from "@/stores/PriceModelStore";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import ClientProductsTable from "@/components/table/clientModel/ClientProductsTable.vue";
import PackageProductsTable from "@/components/table/packageModel/PackageProductsTable.vue";
import {usePackageModelStore} from "@/stores/PackageModelStore";
import moment from "moment-timezone";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {

  setup() {

    const clientModelStore = useClientModelStore();
    const packageModelStore = usePackageModelStore();
    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const pageHeaderStore = usePageHeaderStore();
    const route = useRoute();
    const toast = useToast();
    const router = useRouter();
    const priceModelId = route.params.id;
    let editPriceModelData = priceModelStore.getPriceModelById(priceModelId);

    editPriceModelData.valid_to = moment.utc(editPriceModelData.valid_to);
    editPriceModelData.valid_from = moment.utc(editPriceModelData.valid_from);
    editPriceModelData.price_model_type_id = Number(editPriceModelData.price_model_type_id);

    return {
      clientModelStore,
      pageHeaderStore,
      toast,
      router,
      priceModelStore,
      moment,
      priceModelId,
      editPriceModelData,
      priceModelTypeStore,
      packageModelStore,
    };
  },

  async mounted() {

    const breadcrumbs = [
      { title: 'Preismodelle', href: '/preismodelle' },
      { title: 'Bearbeiten' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    // Actions setzen
    const actions = [
      { label: 'Speichern', class: 'button-standard', onClick: this.savePriceModel },
    ];

   this.pageHeaderStore.setActions(actions);

    if (this.priceModelTypeStore.priceModelTypeData.length <= 0) {
      await this.priceModelTypeStore.getPriceModelTypes();
    }
  },

  components: {
    PackageProductsTable,
    ClientProductsTable,
    ckeditor: CKEditor.component,
    VueDatePicker,
  },

  data() {
    return {
      editor: ClassicEditor,
      selectedFile: null,
      dialogDelete: false,
      dialogCreateProduct: false,
      dialogEdit: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        position: 0,
        name: "",
        price_unique: 0,
        price_per_client: 0,
        minimum_amount: 0,
        description: "",
        target_group_id: 0,
        mask_id: 0,
        column: 0,
        order: 0,
        intensive: false,
        intensive_clients: false,
        suggested: false,
        contract_page_3_text: "",
      },

      pageIsLoading: true,

      headers: [
        {title: 'ID', align: 'center', key: 'id', sortable: false, visible: false},
        {title: 'Position', align: 'center', key: 'position', sortable: false},
        {title: 'Bezeichnung', align: 'center', key: 'name', sortable: false},
        {title: 'Preis einmalig', align: 'center', key: 'price_unique', sortable: false},
        {title: 'Preis je Klient', align: 'center', key: 'price_per_client', sortable: false},
        {title: 'Mindestanzahl', align: 'center', key: 'minimum_amount', sortable: false},
        {title: 'Beschreibung', align: 'center', key: 'description', sortable: false},
        {title: 'Zielgruppe', align: 'center', key: 'target_group_id', sortable: false},
        {title: 'Maske', align: 'center', key: 'mask_id', sortable: false},
        {title: 'Spalte', align: 'center', key: 'column', sortable: false},
        {title: 'Reihenfolge', align: 'center', key: 'order', sortable: false},
        {title: 'Intensiv', align: 'center', key: 'intensive', sortable: false},
        {title: 'Klienten (intensiv)', align: 'center', key: 'intensive_clients', sortable: false},
        {title: 'Empfohlen', align: 'center', key: 'suggested', sortable: false},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},
      ],
    }
  },

  computed: {
    de() {
      return de
    },
  },

  methods: {

    onFileSelectedProductDescription(event) {
      console.log(event.target.files[0]);
      this.editPriceModelData.product_description_file = event.target.files[0];
    },

    onFileSelectedAGB(event) {
      console.log(event.target.files[0]);
      this.editPriceModelData.agb_file = event.target.files[0];
    },

    onFileSelectedOrderProcessingContract(event) {
      console.log(event.target.files[0]);
      this.editPriceModelData.order_processing_contract_file = event.target.files[0];
    },

    onFileSelectedTransparency(event) {
      console.log(event.target.files[0]);
      this.editPriceModelData.transparency_file = event.target.files[0];
    },

    async savePriceModel() {

      this.editPriceModelData.valid_from = this.moment(this.editPriceModelData.valid_from).toISOString();
      this.editPriceModelData.valid_to = this.moment(this.editPriceModelData.valid_to).toISOString();
      this.editPriceModelData.contract_page_3_text = this.editedItem.contract_page_3_text;

      console.log("to update", this.editPriceModelData.product_description_file);

      await this.priceModelStore.updateSinglePriceModel(this.priceModelId, this.editPriceModelData);
      this.toast.success("Preismodell erfolgreich aktualisiert!")

    },
  }
};
</script>

<style scoped>

.even-flex-basis {
  flex-basis: 50%;
}

.foreground{
  z-index: 99999;
}

.datepicker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-card{
  margin-top: 32px;
  margin-bottom: 32px;}

.v-card-text {
  padding: 0;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.v-card-text {
  padding: 20px;
}

.dp__outer_menu_wrap .dp__menu {
  position: relative !important;
  top: 20px !important;
  left: 10px !important;
  background-color: #f0f0f0 !important;
}

</style>
