import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const useCustomerStore = defineStore('CustomerStore', {

    state: () => {

        const customerData: any[] = [];

        return {
            customerData
        }

    },
    actions: {

        async updateSingleCustomer(idToUpdate: any, elementToUpdate: any) {
            const userStore = useUserStore();
            const data = await axiosInstance.put(API_URL + '/api/customer/' + idToUpdate, elementToUpdate, {
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                },
            });

        },

        openCustomerFile(customerId: number, fileType: string) {

            let url = '';

            if (fileType === 'ik') {
                url = API_URL + '/api/customer/' + customerId + '/file/ik';
            } else if (fileType === 'membership') {
                url = API_URL + '/api/customer/' + customerId + '/file/membership';
            } else {
                url = API_URL + '/api/customer/' + customerId + '/file/user_agreement';
            }

            window.open(url, '_blank');

        },

        async getCustomers() {

            const userStore = useUserStore();

            const data = await axiosInstance.get(API_URL + '/api/customer/', {
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                }
            })
            this.customerData = data.data;
        },
    },
    persist: false,
});