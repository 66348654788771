<template>

  <abstract-data-table

  title="Aktive ICD-10 Daten"
  :is-loading="isLoading"
  :items="icdTensStore.icdTenData"
  :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>
    </template>

    <template #item.date = "{item}" >
      {{ formatDate(item.date, false, "LL") }}
    </template>

    <template #no-data>
      <p>Keine Produkte verfügbar</p>
    </template>
  </abstract-data-table>

</template>

<script>

import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useICDTenStore} from "@/stores/masterData/ICDTenStore";
import {formatDate} from "@/services/Helper.service";

export default {
  components: {
    AbstractDataTable,
  },

  setup() {

    const icdTensStore = useICDTenStore();
    const toast = useToast();
    const router = useRouter();

    return {
      icdTensStore,
      toast,
      router,
    };
  },

  async mounted() {
    this.isLoading = true;
    await this.icdTensStore.getICDTenData();
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      backofficeMask: 'ICDTen',
      hasEditRights: false,

      headers: [
        {title: 'Code', align: 'center', key: 'code', sortable: true},
        {title: 'Titel', align: 'center', key: 'title', sortable: true},
        {title: 'Beschreibung', align: 'center', key: 'description', sortable: false},
        {title: 'Stand', align: 'center', key: 'date', sortable: false},
        {title: 'Version', align: 'center', key: 'version', sortable: false},
      ],
    }
  },

  methods: {
    formatDate,
    async refreshTable() {
      await this.icdTensStore.getICDTenData();
      this.toast.success("Tabelle aktualisiert");
    },
  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

