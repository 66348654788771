<template>

  <abstract-data-table

  title=""
  :is-loading="isLoading"
  :items="maskStore.getMaskByPriceModelTypeId(priceModelTypeId)"
  :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>
    </template>

    <template #item.actions="{ item }">
      <button @click="editItem(item)" class="table-data-action-button" title="Bearbeiten" v-if="hasEditRights">
        <v-icon>mdi-pencil</v-icon>
      </button>
    </template>

    <template #no-data>
      <p>Keine Produkte verfügbar</p>
    </template>
  </abstract-data-table>

  <EditMaskDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                  @close-edit-dialog="closeEditDialog"
                  @update-dialog-item="updateDialogItem"/>

</template>

<script>

import {usePriceModelStore} from "@/stores/PriceModelStore";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useToast} from "vue-toastification";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useMaskStore} from "@/stores/MaskStore";
import EditMaskDialog from "@/components/table/mask/EditMaskDialog.vue";
import {hasEditRightsForMask} from "@/services/Helper.service";
export default {
  components: {
    EditMaskDialog,
    AbstractDataTable,
  },

  setup() {

    const maskStore = useMaskStore();
    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const toast = useToast();

    return {
      toast,
      maskStore,
      priceModelStore,
      priceModelTypeStore
    };
  },

  async mounted() {

    this.isLoading = true;
    this.hasEditRights = await hasEditRightsForMask(this.BackofficeMask);
    await this.maskStore.getMasks();
    this.isLoading = false;

  },

  props: {
    priceModelTypeId: {
      required: true
    }
  },

  data() {
    return {
      isLoading: true,
      dialogEdit: false,
      BackofficeMask: 'Preismodelle',
      hasEditRights: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        description: 0,
        price_model_id: 0,
      },

      headers: [
        {title: 'Name', align: 'center', key: 'name', sortable: true},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},

      ],
    }
  },

  methods: {
    async refreshTable() {
      await this.maskStore.getMasks();
      this.toast.success("Tabelle aktualisiert");
    },

    async updateDialogItem(editItemFromDialog) {
      await this.maskStore.updateSingleMask(editItemFromDialog.id, editItemFromDialog);
      await this.maskStore.getMasks();
      this.toast.success("Maske erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    closeEditDialog() {
      this.dialogEdit = false;
    },
  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

