import {useUserStore} from "@/stores/UserStore";
import {RightsResponseModel} from "@/models/rights.response.model";

export default {
    loggedIn() {
        const userStore = useUserStore();
        return userStore.user.token != null;
    },

    async hasRightsForApp(){
        const userStore = useUserStore();
        return userStore.checkRightsForApp();
    },

    async logIn() {
        const userStore = useUserStore();
        await userStore.authenticateUser();
    },

    async logOut() {

        const userStore = useUserStore();
        userStore.backofficeRights = [];
        await userStore.logoutUser();
    },
    async getBackofficeRights ():Promise<Array<RightsResponseModel>> {
        const userStore = useUserStore();
        return userStore.getBackofficeRights();
    }
};
