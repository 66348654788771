<template>

  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="updateItemConfirm">

      <v-card>
        <v-card-title>

          <div class="d-flex flex-row justify-start w-100">
            <h3>Unternehmensform aktualisieren</h3>
          </div>

        </v-card-title>

        <v-card-text>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">
              <v-card-item class="w-100">
                <div class="d-flex flex-column">
                  <v-label for="name">Name</v-label>
                  <v-text-field id="name" v-model="getEditItem.name" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">
              <v-card-item class="w-100">
                <div class="d-flex flex-column">
                  <v-label for="activePriceModel">Aktives Preismodell</v-label>

                  <v-select :items="priceModelStore.getActivePriceModel" item-title="id" item-value="id"
                            v-model="getEditItem.price_model_id"/>
                </div>
              </v-card-item>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeEdit">Abbrechen</button>
            <div class="ma-2"></div>
            <button class="button-standard" type="submit">Maske aktualisieren</button>
          </div>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import {usePriceModelStore} from "@/stores/PriceModelStore";

export default {

  setup() {
    const packageModelStore = useClientModelStore();
    const priceModelStore = usePriceModelStore();
    const toast = useToast();
    return {packageModelStore, toast, priceModelStore}
  },

  methods: {
    closeEdit(e) {
      this.$emit("close-edit-dialog");
      e.preventDefault();
    },

    async updateItemConfirm() {
      const isFormValid = await this.$refs.form.validate();

      if (isFormValid.valid) {
        this.$emit("update-dialog-item", this.getEditItem);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    }
  },

  data() {
    return {

    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    }
  },
  computed: {

    isDialogOpen() {
      return this.dialogOpen;
    },
    getEditItem() {
      return this.editItem;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
