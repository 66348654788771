<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <div class="d-flex flex-column">

        <div class="d-flex flex-row">

          <div class="spacer">
            <div class="d-flex flex-row align-center justify-center w-100 h-100">
              <h1 class="mb-0"> Statistik - 1</h1>
            </div>
          </div>

          <v-spacer class="ma-2"/>

          <div class="spacer ">
            <div class="d-flex flex-row align-center justify-center w-100 h-100">
              <h1 class="mb-0"> Statistik - 2</h1>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row mt-4">
          <div class="spacer">
            <div class="d-flex flex-row align-center justify-center w-100 h-100">
              <h1 class="mb-0"> Statistik - 3</h1>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>


</template>


<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {
  computed: {
    auth() {
      return auth
    }
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();

    return {pageHeaderStore};
  },

  mounted(){
    const breadcrumbs = [
      { title: 'Matomo - Statistiken' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);
  },

  created() {


  },
}

</script>


<style scoped>

.home-container {
  height: 100%;
}

.first-span {
  margin-right: 8px;
}

.ufo-standard {
  margin-bottom: 32px;
}

img {
  width: 20px;
  height: 20px;
}

p {
  color: white;
}

h1 {
  margin-bottom: 64px;
  color: white;
}


.spacer {
  height: 400px;
  width: 100%;
  background: #2F394D;
  border: 1px solid black;
  border-radius: 25px;
}


</style>
