import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useMaskStore} from "@/stores/MaskStore";
import moment from "moment-timezone";
import auth from "@/auth";

export const API_URL = "https://backoffice-backend.jg-agency.de"
//export const API_URL = process.env.VUE_APP_API_URL;

export function convertToGermanCurrency(price: number) {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
}


export function formatDate(date: any,utc = false, format = "LLL",) {
    if(utc){
        return moment(date).utc().format(format);
    } else {
        return moment(date).format(format);
    }
}
export function translateMaskIdToText(maskId: number) {

    const maskStore = useMaskStore();
    if (maskStore.maskData.length > 0) {

        const maskObj = maskStore.maskData.find(function (obj) {

            if (Number(obj.id) === Number(maskId)) {
                return obj;
            }
        });

        if (!maskObj || !maskObj.name) {
            return "Mask mit id: " + maskId + " nicht gefunden";
        } else {
            return maskObj.name;
        }
    }
}

export function translatePriceModelItemTypeIdToString(priceModelTypeId: number) {

    const priceModelTypeStore = usePriceModelTypeStore();

    if (priceModelTypeStore.priceModelTypeData.length > 0) {

        const priceModelTypeObject = priceModelTypeStore.priceModelTypeData.find(function (obj) {
            if (Number(obj.id) === Number(priceModelTypeId)) {
                return obj;
            }
        });
        if (!priceModelTypeObject?.name) {
            return "Keine Zuordnung";
        } else {
            return priceModelTypeObject.name;
        }
    }
}

export async function findSupportUserRole(tokenParsed:any) {
    if (!tokenParsed) {
        return -1;
    }

    const institutionEntry = Object.entries(tokenParsed.institutions).find(([key]) => key === "b7f98a31-0287-467a-8514-7c0c07843379");

    if (!institutionEntry) {
        return -1;
    }

    const [,institutionData] = institutionEntry;
    return findSupportUserIndex(institutionData);
}

function findSupportUserIndex(data: unknown) {
    if (data) {
        if (typeof data === 'object' && 'roles' in data) {
            const rolesArray = data.roles;
            if (Array.isArray(rolesArray)) {
                const index = rolesArray.indexOf('SUPPORT_USER');
                return index !== -1 ? index : -1;
            } else {
                return -1;
            }
        }
    }
}

export async function hasEditRightsForMask(maskName: string) {
    const userRights = await auth.getBackofficeRights();
    return userRights.some((right) => (right.BackofficeMask.name === maskName && right.write));
}
