<template>
  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="createItemConfirm">

      <v-card>
        <v-card-title>

          <div class="d-flex flex-row justify-start w-100">
            <h3>Produkt erstellen</h3>
          </div>

        </v-card-title>

        <v-card-text>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="position">Position</v-label>
                  <v-text-field :rules="positionRule" id="position" v-model="newProduct.position" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-25 pl-2 pr-2">
                <div class="d-flex flex-column">
                  <v-label for="price_unique">Preis einmalig</v-label>
                  <v-text-field :rules="priceUniqueRule" id="price_unique" v-model="newProduct.price_unique" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-25 pl-2 pr-2">
                <div class="d-flex flex-column">
                  <v-label for="price_per_month">Preis monatlich</v-label>
                  <v-text-field :rules="pricePerMonthRule" id="price_per_month" v-model="newProduct.price_per_month"
                                type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="price_saving">Preis Ersparnis</v-label>
                  <v-text-field :rules="priceSavingRule" id="price_saving" v-model="newProduct.price_saving"
                                type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">
              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Module</v-label>
                  <ckeditor :editor="editor" id="description_module" v-model="newProduct.description_module"
                            :config="editorConfig"/>
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Lizenzen</v-label>
                  <ckeditor :editor="editor" id="newProduct.description_licence"
                            v-model="newProduct.description_licence"
                            :config="editorConfig"/>
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Service</v-label>
                  <ckeditor :editor="editor" id="newProduct.description_service"
                            v-model="newProduct.description_service"
                            :config="editorConfig"/>
                </div>
              </v-card-item>

              <v-card-item class="w-25">
                <div class="d-flex flex-column">
                  <v-label for="order">Beschreibung Laufzeit</v-label>
                  <ckeditor :editor="editor" id="newProduct.description_runtime"
                            v-model="newProduct.description_runtime"
                            :config="editorConfig"/>
                </div>
              </v-card-item>
            </div>

            <div class="d-flex flex-row">

              <v-card-item class="w-50">
                <div class="d-flex flex-column">
                  <v-label for="position">Bezeichnung</v-label>
                  <v-text-field :rules="nameRule" id="position" v-model="newProduct.name" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>

              <v-card-item class="w-50">
                <div class="d-flex flex-column">
                  <v-label for="order">Reihenfolge</v-label>
                  <v-text-field :rules="orderRule" id="order" v-model="newProduct.order" type="text"
                  ></v-text-field>
                </div>
              </v-card-item>
              </div>

            <div class="d-flex flex-row">
              <v-card-item class="w-33 ">
                <div class="d-flex flex-column">
                  <v-label for="bestseller">Bestseller</v-label>
                  <v-select id="bestseller" :items="activeOrInactive"
                            v-model="newProduct.bestseller"></v-select>
                </div>
              </v-card-item>

              <v-card-item class="w-33 ">
                <div class="d-flex flex-column">
                  <v-label for="active">Aktiv</v-label>
                  <v-select id="active" :items="activeOrInactive"
                            v-model="newProduct.active"></v-select>
                </div>
              </v-card-item>

              <v-card-item class="w-33">
                <div class="d-flex flex-column">
                  <v-label for="mask_id">Maske</v-label>
                  <v-select id="mask_id" item-title="name" :items="maskStore.getMaskByPriceModelTypeId(2)" item-value="id"
                            v-model="newProduct.mask_id" type="text"
                  ></v-select>
                </div>
              </v-card-item>

            </div>
          </div>
        </v-card-text>


        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeCreate">Abbrechen</button>
            <div class="ma-2"></div>
            <button class="button-standard" type="submit">Produkt anlegen</button>
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useToast} from "vue-toastification";
import {useMaskStore} from "@/stores/MaskStore";
import {useTargetGroupStore} from "@/stores/TargetGroupStore";
import {usePackageModelStore} from "@/stores/PackageModelStore";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue";

export default {

  setup() {
    const packageModelStore = usePackageModelStore();
    const toast = useToast();
    const targetGroupStore = useTargetGroupStore();
    const maskStore = useMaskStore();
    return {packageModelStore, toast, maskStore, targetGroupStore}
  },


  components: {
    ckeditor: CKEditor.component
  },


  async mounted() {
    await this.targetGroupStore.getTargetGroups();
    await this.maskStore.getMasks();
  },

  methods: {
    closeCreate(e) {
      this.$emit("close-create-dialog");
      e.preventDefault();
    },

    async createItemConfirm() {
      const isFormValid = await this.$refs.form.validate();

      if (isFormValid.valid) {
        this.$emit("confirm-create-item", this.newProduct);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    }
  },

  data() {
    return {

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },

      activeOrInactive: [{title: 'Aktiv', value: true}, {title: 'Inaktiv', value: false}],

      newProduct: {
        id: 0,
        position: "",
        name: "",
        price_unique: 0,
        price_per_month: 0,
        description_module: "",
        description_licence: "",
        description_service: "",
        description_runtime: "",
        price_model_id: this.priceModelId,
        mask_id: this.maskStore.maskData.id,
        order: 0,
        bestseller: false,
        active: false,
        price_saving: 0,
      },

      positionRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[A-Za-z0-9]{3,6}$/;
          if (!regex.test(v)) {
            return 'Der Name muss alphanumerisch sein und aus 3 bis 6 Zeichen bestehen';
          }
          return true;
        }
      ],
      priceUniqueRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }
          return true;
        }
      ],
      pricePerMonthRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }
          return true;
        }
      ],
      priceSavingRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[-+]?[0-9]*\.?[0-9]+(,[0-9]+)?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Zahl ein. Erlaubt sind ganze Zahlen und Dezimalzahlen';
          }
          return true;
        }
      ],
      nameRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^.{1,150}$/;
          if (!regex.test(v)) {
            return 'Der eingegebene Text überschreitet die maximale Zeichenlänge von 150 Zeichen. Bitte kürzen Sie den Text auf maximal 500 Zeichen.';
          }
          return true;
        }
      ],
      descriptionRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^.{1,500}$/;
          if (!regex.test(v)) {
            return 'Der eingegebene Text überschreitet die maximale Zeichenlänge von 500 Zeichen. Bitte kürzen Sie den Text auf maximal 500 Zeichen.';
          }
          return true;
        }
      ],
      orderRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^(?:[1-9]|[1-9][0-9])$/;
          if (!regex.test(v)) {
            return 'Die eingegebene Zahl ist ungültig. Bitte geben Sie eine zweistellige Zahl im Bereich von 1 bis 99 ein.';
          }
          return true;
        }
      ],
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    priceModelId: {
      required: true
    }
  },
  computed: {
    isDialogOpen() {
      return this.dialogOpen;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
