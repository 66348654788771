<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <v-card class="flex-grow-1">

        <v-card-title>Preismodell - Konfiguration</v-card-title>

        <v-card-text>

          <div class="d-flex flex-column w-100">
            <p class="b1 mb-2">ICD-10 Datei Import</p>
            <v-file-input class="flex-grow-1" accept=".xml" @change="onFileSelectedProductDescription"/>
          </div>

        </v-card-text>
      </v-card>

      <ICDTenTable/>

    </div>
  </div>
</template>


<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";
import {useICDTenStore} from "@/stores/masterData/ICDTenStore";
import ICDTenTable from "@/components/table/icdten/ICDTenTable.vue";
import {useToast} from "vue-toastification";

export default {
  components: {ICDTenTable},
  computed: {
    auth() {
      return auth
    }
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();
    const toast = useToast();
    const icdTenStore = useICDTenStore();
    return {pageHeaderStore, icdTenStore, toast};
  },

  data() {
    return {
      icdTenFile: null,
    };
  },

  async mounted(){
    const breadcrumbs = [
      { title: 'ICD-10 Daten' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    // Actions setzen
    const actions = [
      { label: 'Speichern', class: 'button-standard', onClick: this.saveIdcTenData },
    ];

    this.pageHeaderStore.setActions(actions);

  },

  methods: {
    onFileSelectedProductDescription(event) {
      this.icdTenFile = event.target.files[0];
    },

    async saveIdcTenData() {

      if(this.icdTenFile){
        await this.icdTenStore.importIDCTenData(this.icdTenFile);
        this.toast.success("Daten erfolgreich importiert")
      } else {
        this.toast.error("Keine Datei ausgewählt")
      }
    },
  },
}

</script>


<style scoped>

.even-flex-basis {
  flex-basis: 50%;
}

.datepicker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-card{
  margin-top: 32px;
  margin-bottom: 32px;}

.v-card-text {
  padding: 0;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.v-card-text {
  padding: 20px;
}

</style>
