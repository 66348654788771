import {createApp} from 'vue';
import App from './App.vue';
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import {loadFonts} from "@/plugins/webfontloader";
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-vue';
import router from './router';
import Toast from "vue-toastification";
import moment from 'moment-timezone';
import 'moment/locale/de';
import "vue-toastification/dist/index.css";
import keycloak from './keycloak';

moment.tz.setDefault("Europe/Berlin");
moment.locale("de");

loadFonts().then();

// @ts-ignore
createApp(App).provide('$moment', moment).use(vuetify).use(pinia, axios).use(Toast, CKEditor).use(router).mount('#app')
