<template>
    <v-sheet
        class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 ma-4"
        elevation="2" height="250" rounded max-width="800" width="100%">
      <div>
        <h2 class="h1-poppins font-weight-black main-color-1">Keine Berechtigung</h2>
        <div>
          <p class="ma-4">Ihr Account besitzt nicht die benötigten Berechtigungen um diese Seite zu benutzen.
          </p>
        </div>
        <router-link :to="{ path: '/home' }"><button class="button-standard">Zurück zur Startseite</button></router-link>
      </div>
    </v-sheet>
</template>

<script>
export default {
  components: {

  },
};
</script>

<style>
</style>
