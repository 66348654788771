<template>

  <abstract-data-table

      title=""
      :is-loading="isLoading"
      :items="customerStore.customerData"
      :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>
    </template>

    <template #item.actions="{ item }">
      <button v-if="hasEditRights" @click="editItem(item)" class="table-data-action-button" title="Bearbeiten">
        <v-icon>mdi-pencil</v-icon>
      </button>
      <button @click="openSupportDialog" class="table-data-action-button" title="Bearbeiten">
        <v-icon>mdi-account-switch</v-icon>
      </button>
    </template>

    <template #item.customer_number="{ item }">
      <div v-if="item.customer_number"> {{ item.customer_number }}</div>
      <div v-else>Nicht gesetzt</div>
    </template>

    <template #item.business_type_id="{ item }">
      {{ businessTypeStore.translateBusinessTypeIdToText(item.business_type_id) }}
    </template>
  </abstract-data-table>

  <EditCustomerDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                      @close-edit-dialog="closeEditDialog" @update-dialog-item="updateDialogItem"/>

  <SupportCustomerDialog v-model:dialog-open="supportDialog" :edit-item="supportItem"
                         @close-support-dialog="closeSupportDialog" @update-support-dialog="submitSupportDialog"/>


</template>

<script>

import {usePriceModelStore} from "@/stores/PriceModelStore";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useToast} from "vue-toastification";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useCustomerStore} from "@/stores/CustomerStore";
import EditCustomerDialog from "@/components/table/customer/EditCustomerDialog.vue";
import {useContractStore} from "@/stores/ContractStore";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";
import {hasEditRightsForMask} from "@/services/Helper.service";
import SupportCustomerDialog from "@/components/table/customer/SupportCustomerDialog.vue";

export default {
  components: {
    SupportCustomerDialog,
    EditCustomerDialog,
    AbstractDataTable,
  },

  setup() {

    const customerStore = useCustomerStore();
    const businessTypeStore = useBusinessTypeStore();
    const contractStore = useContractStore();
    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const toast = useToast();

    return {
      toast,
      customerStore,
      priceModelStore,
      priceModelTypeStore,
      contractStore,
      businessTypeStore,
    };
  },

  async mounted() {

    this.isLoading = true;
    await this.customerStore.getCustomers();
    this.hasEditRights = await hasEditRightsForMask(this.backofficeMask);
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      dialogEdit: false,
      supportDialog: false,
      backofficeMask: 'Kunden',
      hasEditRights: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        customer_number: '',
        description: 0,
        price_model_id: 0,
      },
      supportItem: {
        reason_support: '',
      },

      headers: [
        {title: 'Kundennummer', align: 'center', key: 'customer_number', sortable: false},
        {title: 'Name des Unternehmens', align: 'center', key: 'company', sortable: true},
        {title: 'Unternehmensform', align: 'center', key: 'business_type_id', sortable: false},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},

      ],
    }
  },

  methods: {
    async refreshTable() {
      await this.customerStore.getCustomers();
      this.toast.success("Tabelle aktualisiert");
    },

    async updateDialogItem(editItemFromDialog) {
      await this.customerStore.updateSingleCustomer(editItemFromDialog.id, editItemFromDialog);
      await this.customerStore.getCustomers();
      this.toast.success("Produkt erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    closeEditDialog() {
      this.dialogEdit = false;
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    closeSupportDialog() {
      this.supportDialog = false;
    },

    openSupportDialog() {
      this.supportDialog = true;
    },

    submitSupportDialog() {
      this.supportDialog = false;
    },
  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

