import axios from 'axios';
import router from '@/router';
import {useToast} from "vue-toastification";

const axiosInstance = axios.create({});
const toast = useToast();

axiosInstance.interceptors.request.use(
    config => {
        // Bearbeiten oder loggen der Anfragekonfiguration
        return config;
    },
    async function (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            alert(`Unauthorized Access: ${errorMessage}`);
            return router.replace('/unauthorized').then(() => Promise.reject(error));
        }
        toast.error(`Response Error: ${errorMessage}`);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            alert(`Unauthorized Access: ${errorMessage}`);
            return router.replace('/unauthorized').then(() => Promise.reject(error));
        }
        toast.error(`Response Error: ${errorMessage}`);
        return Promise.reject(error);
    }
);

export default axiosInstance;
