<template>

    <v-list density="compact" nav v-model:opened="opened">
      <v-list-item prepend-icon="mdi-home" title="Startseite" value="home" to="/home" :exact="true"/>

      <v-list-group value="LogingAndRights">
        <template v-slot:activator="{ props }">
          <v-list-item
              v-bind="props"
              prepend-icon="mdi-account-circle"
              title="Login und Rechte"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi mdi-account" title="Mitarbeiter" value="employees" to="/mitarbeiter" :exact="true"></v-list-item>

      </v-list-group>

      <v-list-group value="Onboarding">
        <template v-slot:activator="{ props }">
          <v-list-item
              v-bind="props"
              prepend-icon="mdi-account-circle"
              title="Onboarding"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-file-document" title="Preismodelle" value="priceModel" to="/preismodelle" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="Masken" value="masks" to="/masken" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="Unternehmensformen" value="businessTypes" to="/unternehmensformen" :exact="true"/>

      </v-list-group>

      <v-list-group value="Stammdaten">
        <template v-slot:activator="{ props }">
          <v-list-item
              v-bind="props"
              prepend-icon="mdi-account-circle"
              title="Stammdaten"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-file-document" title="ICD-10" value="icdten" to="/icdten" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="BMP - Darreichungsformen " value="bmpDosageForms" to="/bmp-dosage-forms" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="BMP - Zwischenüberschriften" value="bmpSubHeadings" to="/bmp-sub-headings" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="BMP - Dosiereinheiten" value="bmpDosingUnit" to="/bmp-dosing-unit" :exact="true"/>

      </v-list-group>

      <v-list-group value="odcare">
        <template v-slot:activator="{ props }">
          <v-list-item
              v-bind="props"
              prepend-icon="mdi-account-circle"
              title="od | care"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-file-document" title="Maßnahmenplan" value="actionplan" to="/actionplan" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="Blutzucker" value="bloodsugar" to="/bloodsugar" :exact="true"/>
        <v-list-item prepend-icon="mdi-file-document" title="Tageszeiten" value="daytimes" to="/daytimes" :exact="true"/>


      </v-list-group>

      <v-list-item prepend-icon="mdi-file-document" title="Kunden" value="customers" to="/kunden" :exact="true"/>
      <v-list-item prepend-icon="mdi-file-document" title="Nachrichten" value="messages" to="/nachrichten" :exact="true"/>
      <v-list-item prepend-icon="mdi-file-document" title="QR-Code" value="qr-code" to="/qr-code" :exact="true"/>

    </v-list>
</template>


<style scoped>

.v-list-item{
  color: white;
  font-weight: 700;
}

</style>


<script>

export default {

  data(){
    return {
      opened: ['Onboarding']
    }
  }
}


</script>

<style scoped>

.v-list-item{
  --indent-padding: 16px !important;
}

</style>
