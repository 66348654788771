<template>

  <abstract-data-table

  title=""
  :is-loading="isLoading"
  :items="businessTypeStore.businessTypeData"
  :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>
    </template>

    <template #item.price_model_id="{ item }">
      {{ item.price_model_id }}
    </template>

    <template #item.actions="{ item }">
      <button v-if="hasEditRights" @click="editItem(item)" class="table-data-action-button" title="Bearbeiten" >
        <v-icon>mdi-pencil</v-icon>
      </button>
    </template>

    <template #no-data>
      <p>Keine Produkte verfügbar</p>
    </template>
  </abstract-data-table>

  <EditBusinessTypeDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                          @close-edit-dialog="closeEditDialog"
                          @update-dialog-item="updateDialogItem"/>

</template>

<script>

import {usePriceModelStore} from "@/stores/PriceModelStore";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";
import EditBusinessTypeDialog from "@/components/table/businessType/EditBusinessTypeDialog.vue";
import {hasEditRightsForMask} from "@/services/Helper.service";

export default {
  components: {
    EditBusinessTypeDialog,
    AbstractDataTable,
  },

  setup() {

    const businessTypeStore = useBusinessTypeStore();
    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const toast = useToast();
    const router = useRouter();

    return {
      businessTypeStore,
      toast,
      router,
      priceModelStore,
      priceModelTypeStore
    };
  },

  async mounted() {
    this.isLoading = true;
    this.hasEditRights = await hasEditRightsForMask(this.backofficeMask);
    await this.businessTypeStore.getBusinessTypes();
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      dialogEdit: false,
      backofficeMask: 'Kunden',
      hasEditRights: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: 0,
        price_model_id: 0,
      },

      headers: [
        {title: 'Name', align: 'center', key: 'name', sortable: true},
        {title: 'Preismodell ID', align: 'center', key: 'price_model_id', sortable: true},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},
      ],
    }
  },

  methods: {
    async refreshTable() {
      await this.businessTypeStore.getBusinessTypes();
      this.toast.success("Tabelle aktualisiert");
    },

    async updateDialogItem(editItemFromDialog) {
      await this.businessTypeStore.updateSingleBusinessType(editItemFromDialog.id, editItemFromDialog);
      await this.businessTypeStore.getBusinessTypes();
      this.toast.success("Unternehmensform erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    closeEditDialog() {
      this.dialogEdit = false;
    },

  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

