<template>
  <abstract-data-table

      title="Benutzer Rechte festlegen"
      :items="rightsModelStore.rightsModelData"
      :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>
    </template>

    <template #item.read="{ item }">
      <v-checkbox v-model="item.read" density="compact"
                  hide-details="auto"
                  ></v-checkbox>
    </template>

    <template #item.write="{ item }">
      <v-checkbox v-model="item.write" density="compact"
                  hide-details="auto"
                  ></v-checkbox>
    </template>

    <template #no-data>
      <p>Keine Daten verfügbar</p>
    </template>
  </abstract-data-table>

</template>

<script>
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useToast} from "vue-toastification";
import {useRightsModelStore} from "@/stores/RightsModelStore";
import {useUserStore} from "@/stores/UserStore";

export default {
  components: {
    AbstractDataTable,
  },

  setup(){
    const rightsModelStore = useRightsModelStore();
    const userStore = useUserStore();
    const toast = useToast();
    return{toast,rightsModelStore, userStore};
  },

  async mounted() {
    this.isLoading = true;
    await this.rightsModelStore.getRightsModelForUser(this.userItem.keycloakUUID);
    this.isLoading = false;
  },

  props:{
    userItem: {
      required: true
    }
  },

  data(){
    return{
      isLoading: true,

      editedIndex: -1,

      headers: [
        {title: 'Maske', align: 'center', key: 'BackofficeMask.name', sortable: true},
        {title: 'Leserechte', align: 'center', key: 'read', sortable: false},
        {title: 'Schreibrechte', align: 'center', key: 'write', sortable: false},
      ],
    };
  },

  methods: {
    async refreshTable() {
      this.toast.success("Tabelle aktualisiert");
    },

    async updateDialogItem(keycloakUUID) {
      await this.rightsModelStore.createRightsModelForUser(keycloakUUID);
      this.toast.success("Rechte erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    closeEditDialog() {
      this.dialogEdit = false;
    },
  },
}
</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>
