import {useApplicationStore} from "@/stores/ApplicationStore";
import {defineStore} from "pinia";
import axios from "axios";
import auth from "@/auth";
import {useUserStore} from "@/stores/UserStore";
import {NewsModelResponseModel} from "@/models/newsModelResponse.model";
import {NewsModelRequestModel} from "@/models/newsModelRequest.model";

export const useNewsModelStore = defineStore('NewsModelStore', {

    state: () => {

        const newsModelData: NewsModelResponseModel[] = [];

        return {
            newsModelData: newsModelData
        }

    },
    actions: {

        async getNewsModels() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();

            try {
                const data = await axios.get(applicationStore.getPhxBackendUrl + '/api/bo/nachricht', {
                    headers: {
                        'Authorization': `Bearer ${userStore.user.token}`
                    }
                })
                this.newsModelData = data.data;

            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && (error.response.status === 401 || error.response.status === 403)) {
                    await auth.logOut();
                } else {
                    throw error;
                }
            }
        },


        async createNewsModel(elementToCreate: NewsModelRequestModel) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();

            try {
                const data = await axios.post(applicationStore.getPhxBackendUrl + '/api/bo/nachricht', elementToCreate, {
                    headers: {
                        'Authorization': `Bearer ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },


        getters: {},
        persist: false,
    }
});
