import {defineStore} from "pinia";
import {RightsResponseModel} from "@/models/rights.response.model";
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";
import axiosInstance from "@/plugins/axios";
import {UserModel} from "@/models/user.model";

export const useRightsModelStore = defineStore('RightsModelStore', {


    state: () => {
        const rightsModelData: RightsResponseModel[] = [];
        const userModel: UserModel[] = [];
        return {
            rightsModelData: rightsModelData,
            userModelData: userModel,
        }
    },
    actions: {

        async getRightsModelForUser(keycloakUUID: any) {
            const userStore = useUserStore();

            const rightsData = await axiosInstance.get(API_URL + '/api/user/rights/' + keycloakUUID, {
                headers: {
                    'Authorization': `Bearer ${userStore.user.token}`
                }
            })
            this.rightsModelData = rightsData.data;
        },


        async createRightsModelForUser(keycloakUUID: any) {
            const userStore = useUserStore();

            const data = await axiosInstance.post(API_URL + '/api/user/rights/' + keycloakUUID, this.rightsModelData, {
                headers: {
                    'Authorization': `Bearer ${userStore.user.token}`
                },
            });
        },
        getters: {},
        persist: false,
    }
});
