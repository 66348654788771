<template>

  <abstract-data-table

  title="Aktive BMP - Dosiereinheiten"
  :is-loading="isLoading"
  :items="bmpDosingUnitStore.bmpDosingUnitData"
  :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>
    </template>

    <template #no-data>
      <p>Keine Daten verfügbar</p>
    </template>
  </abstract-data-table>

</template>

<script>

import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {formatDate} from "@/services/Helper.service";
import {useBMPDosingUnitStore} from "@/stores/masterData/BMPDosingUnitStore";

export default {
  components: {
    AbstractDataTable,
  },

  setup() {

    const bmpDosingUnitStore = useBMPDosingUnitStore();
    const toast = useToast();
    const router = useRouter();

    return {
      bmpDosingUnitStore,
      toast,
      router,
    };
  },

  async mounted() {
    this.isLoading = true;
    await this.bmpDosingUnitStore.getBMPDosingUnitData();
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      hasEditRights: false,

      headers: [
        {title: 'Sortierung', align: 'center', key: 'sort', sortable: true},
        {title: 'Kurzform', align: 'center', key: 'short_form', sortable: true},
        {title: 'Langform', align: 'center', key: 'long_form', sortable: true},
        {title: 'Version', align: 'center', key: 'version', sortable: false},
      ],
    }
  },

  methods: {
    formatDate,
    async refreshTable() {
      await this.bmpDosingUnitStore.getBMPDosingUnitData();
      this.toast.success("Tabelle aktualisiert");
    },
  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

