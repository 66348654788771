<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <v-card class="flex-grow-1">

        <v-card-title>Blutzucker - Konfiguration</v-card-title>

        <v-card-text>

          <v-select
              label="Einheit auswählen"
              item-title="name"
              item-value="id"
              :items="bloodSugarStore.bloodSugarTypeData"
              v-model="bloodSugarStore.bloodSugarData.blood_sugar_type_id"
          ></v-select>

        </v-card-text>
      </v-card>
    </div>
  </div>
</template>


<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";
import {useToast} from "vue-toastification";
import {useBloodSugarStore} from "@/stores/BloodSugarStore";


export default {
  computed: {
    auth() {
      return auth
    }
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();
    const toast = useToast();
    const bloodSugarStore = useBloodSugarStore();
    return {pageHeaderStore, bloodSugarStore, toast};
  },

  data() {
    return {
      icdTenFile: null,
    };
  },

  async mounted(){
    const breadcrumbs = [
      { title: 'Blutzucker' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    // Actions setzen
    const actions = [
      { label: 'Speichern', class: 'button-standard', onClick: this.saveBloodSugarData },
    ];

    this.pageHeaderStore.setActions(actions);

    await this.bloodSugarStore.getBloodSugar();
    await this.bloodSugarStore.getBloodSugarTypes();



  },

  methods: {
    onFileSelectedProductDescription(event) {
      this.icdTenFile = event.target.files[0];
    },

    async saveBloodSugarData() {

        await this.bloodSugarStore.updateBloodSugar();
        this.toast.success("Daten erfolgreich importiert")
    },
  },
}

</script>


<style scoped>

.even-flex-basis {
  flex-basis: 50%;
}

.datepicker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-card{
  margin-top: 32px;
  margin-bottom: 32px;}

.v-card-text {
  padding: 0;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.v-card-text {
  padding: 20px;
}

</style>
