<template>
  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-card>
      <v-card-title>

        <div class="d-flex flex-row justify-start w-100">
          <h3>Benutzerrechte aktualisieren</h3>
        </div>

      </v-card-title>

      <v-card-text>

        <div class="d-flex flex-column">

          <v-card-item>
            <div class="d-flex flex-column">
              <v-label for="name">Email</v-label>
              <v-text-field :readonly="true" id="name" v-model="getEditItem.email" type="text"
              ></v-text-field>
            </div>
          </v-card-item>
          <v-card-item>
          <div class="d-flex flex-column">
            <v-label for="name">Aktiv</v-label>

            <v-select :items="activeInactiveSelectItems" item-title="name" item-value="id"
                      v-model="getEditItem.active"/>

          </div>
          </v-card-item>
          <EditEmployeeRightsTable :user-item="getEditItem"/>
        </div>

      </v-card-text>

      <v-card-actions>
        <div class="d-flex flex-row justify-end w-100">
          <button class="button-standard-back" @click="closeEdit">Abbrechen</button>
          <div class="ma-2"></div>
          <button @click="updateItemConfirm" class="button-standard">Rechte speichern</button>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import {useToast} from "vue-toastification";
import {useRightsModelStore} from "@/stores/RightsModelStore";
import EditEmployeeRightsTable from "@/components/table/EmployeeRights/EditEmployeeRightsTable.vue";

export default {
  components: {EditEmployeeRightsTable},

  setup() {
    const rightsModelStore = useRightsModelStore();
    const toast = useToast();
    return {toast, rightsModelStore}
  },

  methods: {
    closeEdit(e) {
      this.$emit("close-edit-dialog");
      e.preventDefault();
    },

    async updateItemConfirm() {
      this.$emit("update-dialog-item", this.getEditItem);
    },
  },

  data() {
    return {
      activeInactiveSelectItems: [
        {id: true, name: "Aktiv"},
        {id: false, name: "Inaktiv"},
      ],
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    }
  },
  computed: {

    isDialogOpen() {
      return this.dialogOpen;
    },
    getEditItem() {
      return this.editItem;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
