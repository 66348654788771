<template>

  <div class="d-flex flex-row justify-space-evenly align-center container">
    <!-- Circle with initials -->
    <div class="d-flex initials align-center justify-center">
      <div>{{getFirstLetter(userStore.user.firstname)}}{{getFirstLetter(userStore.user.lastname)}}</div>
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex flex-row name">
        {{userStore.user.firstname}} {{userStore.user.lastname}}
      </div>
      <div class="mail">info@jg-agency.de</div>
    </div>
    <div>
      <div v-if="auth.loggedIn()" class="d-flex flex-row">
        <button @click="auth.logOut()">
          <v-icon class="white-logo">mdi-logout</v-icon>
        </button>
      </div>
    </div>
  </div>

</template>


<style scoped>

.container{
  padding: 25px 10px;
  background-color: #364159;
}

.initials {
  font-family: var(--main-font-family);
  background-color: var(--main-color-2);
  color: white;
  font-weight: 600;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
}

.name{
  font-family: var(--main-font-family);
  color: white;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

.mail{
  font-family: var(--main-font-family);
  color: white;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

v-icon{
  color: white;
}

</style>


<script>

import auth from "@/auth";
import {useUserStore} from "@/stores/UserStore";

export default {

  setup(){
    const userStore = useUserStore();

    return {userStore}
  },

  methods: {

    getFirstLetter(string) {
      return string?.charAt(0);
    }
  },

  computed: {
    auth() {
      return auth
    }
  }
}


</script>
