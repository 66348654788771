import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const useBusinessTypeStore = defineStore('BusinessTypeStore', {

        state: () => {

            const businessTypeData: any[] = [];

            return {
                businessTypeData
            }

        },
        actions: {

            async updateSingleBusinessType(idToUpdate: any, elementToUpdate: any) {
                const userStore = useUserStore();
                const data = await axiosInstance.put(API_URL + '/api/businessType/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },

            async getBusinessTypes() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/businessType/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.businessTypeData = data.data;
            },
        },

        getters:
            {
                getBusinessTypeDataByPriceModelTypeId: (state) => (priceModelTypeId: number) => {

                    const businessTypeStore = useBusinessTypeStore();
                    if (businessTypeStore.businessTypeData.length > 0) {
                        return businessTypeStore.businessTypeData.filter(businessType =>
                            businessType.price_model_type_id === priceModelTypeId
                        );
                    }
                    return [];
                },
                translateBusinessTypeIdToText:
                    (state) => (businessTypeId: number) => {

                        const businessTypeStore = useBusinessTypeStore();
                        const businessType = businessTypeStore.businessTypeData.find(businessType => businessType.id === Number(businessTypeId));
                        return businessType?.name;

                    }
            }
        ,
        persist: false,
    })
;