<template>

  <div class="page-container">
    <div class="d-flex flex-column">

      <v-expansion-panels variant="accordion" class="my-4" v-model="activePanel">
        <v-expansion-panel
            v-for="(item, i) in priceModelTypeStore.priceModelTypeData"
            :key="i"
            :title="item.name"
        >
          <v-expansion-panel-text>
            <MaskTable
                :price-model-type-id="item.id"/>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <EditMaskDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                      @close-edit-dialog="closeDialogEdit"
                      @update-dialog-item="updateDialogItem"/>

    </div>
  </div>

</template>

<script>
import {usePriceModelStore} from "@/stores/PriceModelStore";
import EditMaskDialog from "@/components/table/mask/EditMaskDialog.vue";
import {useToast} from "vue-toastification";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useMaskStore} from "@/stores/MaskStore";
import {translatePriceModelItemTypeIdToString} from "@/services/Helper.service";
import MaskTable from "@/components/table/mask/MaskTable.vue";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {
  components: {
    MaskTable,
    EditMaskDialog,
  },

  setup() {

    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const pageHeaderStore = usePageHeaderStore();
    const maskStore = useMaskStore();
    const toast = useToast();
    return {priceModelStore, toast, priceModelTypeStore, maskStore, pageHeaderStore};

  },

  async mounted() {

    const breadcrumbs = [
      { title: 'Masken' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    await this.maskStore.getMasks();
    await this.priceModelStore.getPriceModels();
    await this.priceModelTypeStore.getPriceModelTypes();

  },

  data() {
    return {
      dialogEdit: false,
      activePanel: 0,
      editedItem: {
        id: 0,
        name: "",
        description: "",
        price_model_type_id: 0
      },
    }
  },

  methods: {
    translatePriceModelItemTypeIdToString,

    async updateDialogItem(editItemFromDialog) {
      await this.maskStore.updateSingleMask(editItemFromDialog.id, editItemFromDialog);
      await this.maskStore.getMasks();
      this.toast.success("Maske erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    closeDialogEdit() {
      this.dialogEdit = false;
    },

    editItem(item) {

      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    async refreshTable() {
      await this.maskStore.getMasks();
      this.toast.success("Tabelle aktualisiert");
    },

  },
}


</script>

<style scoped>

.table-container {
  border: 1px solid var(--border-color);
  max-height: 100vh;
  overflow-y: auto;
}

td {
  color: var(--table-data-text-color);
  font-family: var(--main-font-family);
}

th {
  color: #607081;
}

.even-row {
  background-color: #F9FAFB; /* Even row color */
}

.odd-row {
  background-color: white; /* Odd row color */
}

.no-data-container {
  background-color: white;
}

</style>