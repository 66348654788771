import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const useBMPDosageFormStore = defineStore('BMPDosageFormsStore', {

    state: () => {

        const bmpDosageFormData: any[] = [];

        return {
            bmpDosageFormData
        }

    },
    actions: {


        async importBMPDosageFormsData(icdTenFile: any) {

            const userStore = useUserStore();
            const formData = new FormData();

            formData.append("bmp_dosage_form_file", icdTenFile);

            const data = await axiosInstance.post(API_URL + '/api/bmpDosageForm/', formData, {
                headers: {
                    'Authorization': `token ${userStore.user.token}`,
                },
            });

        },


        async getBMPDosageFormsData() {

            const userStore = useUserStore();

            const data = await axiosInstance.get(API_URL + '/api/bmpDosageForm/', {
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                }
            })
            this.bmpDosageFormData = data.data;
        },
    },

    getters: {},
    persist: false,
});