import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const useDayTimesStore = defineStore('DayTimesStore', {

        state: () => {

            const dayTimesData: any[] = [];

            return {
                dayTimesData
            }
        },
        actions: {

            async updateDayTimes(idToUpdate: any, elementToUpdate: any) {
                const userStore = useUserStore();

                const data = await axiosInstance.put(API_URL + '/api/dayTimes/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },

            async createDayTime(elementToCreate: any) {
                const userStore = useUserStore();

                const data = await axiosInstance.post(API_URL + '/api/dayTimes/', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },

            async getDayTimes() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/dayTimes/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.dayTimesData = data.data;
            },
        },


        persist: false,
    })
;