<template>

  <div class="d-flex flex-row justify-center">
    <span v-if="isLoading" class="spinner"></span>
  </div>

  <v-data-table
      v-if="!isLoading"
      fixed-header
      height="450px"
      items-per-page="10"
      :headers="headers"
      :items="packageModelStore.packageModelDataByPriceModelData"
      items-per-page-text="Produkte pro Seite"
      item-value="name"
      class="elevation-1 custom-v-data-table"
  >
    <template v-slot:top>
      <v-toolbar>
        <v-toolbar-title>Produkte</v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="d-flex flex-row w-50 pa-4 justify-end">

          <button @click="refreshTable" class="table-header-action-button">
            Tabelle aktualisieren
          </button>

          <div class="ma-2"/>

          <button @click="openCreateDialog" class="table-header-action-button" v-if="hasEditRights">
            Produkt anlegen
          </button>
        </div>

        <EditPackageModelDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                                @close-edit-dialog="closeDeleteDialogEdit"
                                @update-dialog-item="updateDialogItem"/>
        <ConfirmDeleteDialog title="Bist du sicher das du dieses Produkt löschen möchtest?"
                             v-model:dialog-open="dialogDelete"
                             @close-delete-dialog="closeDeleteDialogDelete"
                             @delete-dialog-item="dialogConfirmDeleteItem"/>
        <CreatePackageModelDialog v-model:dialog-open="dialogCreateProduct"
                                  @close-create-dialog="closeCreateDialogItem"
                                  @confirm-create-item="dialogCreateItemConfirm"
                                  :price-model-id="this.priceModelId"/>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">

      <button @click="editItem(item)" class="table-data-action-button" title="Bearbeiten">
        <v-icon>mdi-pencil</v-icon>
      </button>
      <button @click="deleteItem(item)" class="table-data-action-button" title="Löschen">
        <v-icon>mdi-trash-can</v-icon>
      </button>

    </template>

    <template v-slot:[`item.mask_id`]="{ item }">
      <p> {{ translateMaskIdToText(item.mask_id) }} </p>
    </template>

    <template v-slot:[`item.price_unique`]="{ item }">
      <p> {{ convertToGermanCurrency(item.price_unique) }} </p>
    </template>

    <template v-slot:[`item.price_saving`]="{ item }">
      <p> {{ convertToGermanCurrency(item.price_saving) }} </p>
    </template>

    <template v-slot:[`item.price_per_month`]="{ item }">
      <p> {{ convertToGermanCurrency(item.price_per_month) }} </p>
    </template>

    <template v-slot:[`item.bestseller`]="{ item }">
      <ColoredDot :is-active="item.bestseller"/>
    </template>

    <template v-slot:[`item.active`]="{ item }">
      <ColoredDot :is-active="item.active"/>
    </template>

    <template v-slot:no-data>
      <p>Keine Daten</p>
    </template>
  </v-data-table>


</template>


<script>

import ConfirmDeleteDialog from "@/components/table/ConfirmDeleteDialog.vue";
import ColoredDot from "@/components/table/ColoredDot.vue";
import {usePriceModelStore} from "@/stores/PriceModelStore";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import {inject} from "vue";
import {VDataTable} from "vuetify/lib/components/VDataTable";
import {usePackageModelStore} from "@/stores/PackageModelStore";
import EditPackageModelDialog from "@/components/table/packageModel/EditPackageModelDialog.vue";
import CreatePackageModelDialog from "@/components/table/packageModel/CreatePackageModelDialog.vue";
import {convertToGermanCurrency, hasEditRightsForMask, translateMaskIdToText} from "@/services/Helper.service";

export default {
  components: {
    EditPackageModelDialog,
    CreatePackageModelDialog,
    ColoredDot,
    ConfirmDeleteDialog,
    VDataTable
  },

  setup() {

    const packageModelStore = usePackageModelStore();
    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const toast = useToast();
    const router = useRouter();
    const moment = inject('$moment')

    return {
      packageModelStore,
      toast,
      router,
      priceModelStore,
      moment,
      priceModelTypeStore
    };
  },

  async mounted() {
    this.isLoading = true;
    this.hasEditRights = await hasEditRightsForMask(this.backofficeMask);
    await this.packageModelStore.getPackageModelsByPriceModelId(this.priceModelId);
    this.isLoading = false;

  },

  data() {
    return {
      isLoading: true,
      dialogDelete: false,
      dialogCreateProduct: false,
      backofficeMask: 'Preismodelle',
      hasEditRights: false,
      dialogEdit: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        position: "",
        name: "",
        price_unique: 0,
        price_per_month: 0,
        price_deviation: 0,
        price_deviation_months: 0,
        allow_order_amount_input: false,
        description: "",
        mask_id: 0,
        order: 0,
        bestseller: false,
        active: false,
        price_saving: 0,
      },

      pageIsLoading: true,

      headers: [
        {title: 'Position', align: 'center', key: 'position', sortable: true},
        {title: 'Bezeichnung', align: 'center', key: 'name', sortable: true},
        {title: 'Preis monatlich', align: 'center', key: 'price_per_month', sortable: true},
        {title: 'Preis einmalig', align: 'center', key: 'price_unique', sortable: true},
        {title: 'Maske', align: 'center', key: 'mask_id', sortable: true},
        {title: 'Reihenfolge', align: 'center', key: 'order', sortable: true},
        {title: 'Preis Ersparnis', align: 'center', key: 'price_saving', sortable: true},
        {title: 'Bestseller', align: 'center', key: 'bestseller', sortable: true},
        {title: 'Aktiv', align: 'center', key: 'active', sortable: true},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},
      ],
    }
  },

  methods: {
    translateMaskIdToText,
    convertToGermanCurrency,
    async refreshTable() {
      await this.packageModelStore.getPackageModelsByPriceModelId(this.priceModelId);
      this.toast.success("Tabelle aktualisiert");
    },

    openCreateDialog() {
      this.dialogCreateProduct = true;
    },

    closeDeleteDialogEdit() {
      this.dialogEdit = false;
    },

    closeDeleteDialogDelete() {
      this.dialogDelete = false;
    },

    closeCreateDialogItem() {
      this.dialogCreateProduct = false;
    },

    async dialogConfirmDeleteItem() {
      await this.packageModelStore.deleteSinglePackageModel(this.editedItem.id)
      await this.packageModelStore.getPackageModelsByPriceModelId(this.priceModelId);
      this.closeDeleteDialogDelete();
      this.toast.success("Produkt erfolgreich gelöscht!")
    },

    async updateDialogItem(editItemFromDialog) {
      await this.packageModelStore.updateSinglePackageModel(editItemFromDialog.id, editItemFromDialog);
      await this.packageModelStore.getPackageModelsByPriceModelId(this.priceModelId);
      this.toast.success("Produkt erfolgreich aktualisiert!")
      this.dialogEdit = false;
    },

    async dialogCreateItemConfirm(newProduct) {
      await this.packageModelStore.createSinglePackageModel(newProduct);
      await this.packageModelStore.getPackageModelsByPriceModelId(this.priceModelId);
      this.toast.success("Produkt erfolgreich angelegt!")
      this.dialogCreateProduct = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true;
    },
  },

  props: {
    priceModelId: {
      required: true
    }
  }
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

