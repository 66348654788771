<template>
  <v-dialog
      max-width="500"
      v-model="isDialogOpen">
    <v-card>
      <v-card-title class="text-h5 pa-2">

        <div class="d-flex flex-row justify-center w-100">

          <h3>{{ title }}</h3>
        </div>

      </v-card-title>
      <v-card-actions class="pa-2">

        <div class="d-flex flex-row justify-center w-100">

          <button class="button-standard-back" @click="closeDelete">ABBRECHEN</button>
          <div class="ma-2"></div>
          <button class="button-standard" @click="deleteItemConfirm">OK</button>

        </div>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";

export default {

  setup() {
    const clientModelStore = useClientModelStore();
    return {clientModelStore}
  },

  methods: {
    closeDelete() {
      this.$emit("close-delete-dialog");
    },
    deleteItemConfirm() {
      this.$emit("delete-dialog-item", );
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    title: {
      required: true
    }
  },
  computed: {
    isDialogOpen(){
        return this.dialogOpen;
    },
    getTitle(){
      return this.getTitle();
    }
  }
}

</script>


<style scoped>

.v-card-actions{
  border: 1px solid var(--border-color);
}

</style>
