import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const usePageHeaderStore = defineStore('PageHeaderStore', {

    state: () => ({
        breadcrumbs: [
            { disabled: false, href: '/', icon: 'mdi-home' } // Haus-Icon als erstes Element
        ],
        actions: []
    }),
    actions: {
        setBreadcrumbs(breadcrumbs: any) {
            this.breadcrumbs = [
                { disabled: false, href: '/', icon: 'mdi-home' },
                ...breadcrumbs
            ];
        },
        setActions(actions: any) {
            this.actions = actions;
        },
        clearBreadcrumbs() {
            this.breadcrumbs = [];
        },
        clearActions() {
            this.actions = [];
        }
    }
});