<template>
  <abstract-data-table
      title=""
      :items="newsStore.newsModelData"
      :headers="headers">

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>

      <button @click="createItem" class="table-header-action-button">
        neue Nachricht anlegen
      </button>
    </template>


    <template #item.verfassungsdatum=" { item } ">
      {{ formatDate(item.raw.verfassungsdatum) }}
    </template>

    <template #item.content.ablaufdatum=" { item } ">
      {{ formatDate(item.raw.content.ablaufdatum) }}
    </template>


    <template #item.action="{ item }">
      <button @click="editItem(item.raw)" class="table-data-action-button" title="Bearbeiten">
        <v-icon>mdi-eye</v-icon>
      </button>
    </template>

    <template #no-data>
      <p>Keine Produkte verfügbar</p>
    </template>

  </abstract-data-table>

  <EditNewsDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                  @close-edit-dialog="closeEditDialog"/>

  <CreateNewsDialog v-model:dialog-open="dialogCreate" @close-create-dialog="closeCreateDialog"
                    @confirm-create-dialog="dialogConfirmCreateItem"/>

</template>

<script>

import AbstractDataTable from "@/components/table/AbstractDataTable.vue";
import {useNewsModelStore} from "@/stores/NewsModelStore";
import {useToast} from "vue-toastification";
import EditNewsDialog from "@/components/table/news/EditNewsDialog.vue";
import CreateNewsDialog from "@/components/table/news/CreatNewsDialog.vue";
import {inject} from "vue";

export default {
  components: {
    EditNewsDialog,
    CreateNewsDialog,
    AbstractDataTable,
  },

  setup() {
    const newsStore = useNewsModelStore();
    const toast = useToast();
    const moment = inject('$moment');
    return {newsStore, toast, moment};
  },

  async mounted() {
    await this.newsStore.getNewsModels();
  },


  data() {
    return {
      isLoading: true,
      dialogEdit: false,
      dialogCreate: false,
      editedIndex: -1,

      editedItem: {
        news_model_id: 0,
        content: {
          titel: "",
          nachricht: "",
          ablaufdatum: 0
        },
        verfassungsdatum: 0,
        verfasser: ""
      },

      headers: [
        {title: 'Titel', align: 'center', key: 'content.titel', sortable: true},
        {title: 'Verfasser', align: 'center', key: 'verfasser', sortable: true},
        {title: 'gültig von', align: 'center', key: 'verfassungsdatum', sortable: true},
        {title: 'gültig bis', align: 'center', key: 'content.ablaufdatum', sortable: true},
        {title: 'Aktion', align: 'center', key: 'action', sortable: false}
      ],
    }
  },

  methods: {
    async refreshTable() {
      await this.newsStore.getNewsModels();
      this.toast.success("Tabelle aktualisiert");
    },

    async dialogConfirmCreateItem(creatItemFromDialog) {
      await this.newsStore.createNewsModel(creatItemFromDialog);
      await this.newsStore.getNewsModels();
      this.toast.success("Die Nachricht wurde erfolgreich angelegt")
      this.dialogCreate = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    createItem() {
      this.dialogCreate = true;
    },

    closeEditDialog() {
      this.dialogEdit = false;
    },

    closeCreateDialog() {
      this.dialogCreate = false;
    },

    formatDate(date) {
      return this.moment(date).utc().format("LL");
    },
  },
}

</script>

<style scoped>

.custom-v-data-table {
  overflow : scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position : sticky;
  bottom   : 0;
}

.v-toolbar {
  background : white !important;
}

</style>