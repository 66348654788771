<template>
  <v-dialog
      max-width="625"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="createItemConfirm">

      <v-card class="flex-grow-1" >
        <v-card-title> Preismodell anlegen</v-card-title>
        <v-card-text>

          <div class="d-flex flex-column">
            <div class="d-flex flex-row mt-3 mb-3 card-item">
              <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column w-75 mr-2">
                  <label for="selectPriceModel" class="poppins">Preismodell:</label>
                  <v-select id="selectPriceModel" class="even-flex-basis" variant="outlined"
                            density="compact"
                            hide-details="auto"
                            item-title="name"
                            item-value="id"
                            :items="priceModelTypeStore.priceModelTypeData"
                            v-model="priceModelToCreate.price_model_type_id"

                  />
                </div>
                <div class="d-flex flex-column w-25 ml-2">
                  <label for="selectIsPriceModelActive" class="poppins">Aktiv:</label>
                  <v-select id="selectIsPriceModelActive" class="even-flex-basis" variant="outlined"
                            density="compact"
                            hide-details="auto"
                            v-model="priceModelToCreate.active"
                            :items="[{title: 'Aktiv', value: true}, {title: 'Inaktiv', value: false}]"
                  />
                </div>
              </div>


            </div>

            <div class="d-flex flex-row card-item">
              <div class="datepicker-container mr-3 w-50">
                <label for="datepickerFrom" class="poppins">Aktiv von: {{formatDate(priceModelToCreate.valid_from)}}</label>


                <VueDatePicker id="datepickerFrom" class="even-flex-basis"
                               v-model="priceModelToCreate.valid_from"
                               locale="de"
                               :inline="true"
                               text-input auto-apply
                               format="dd.MM.yyyy HH:mm"
                               select-text="Speichern"
                               cancel-text="Abbrechen"/>
              </div>

              <div class="datepicker-container ml-3 w-50">
                <label for="datepickerFrom" class="poppins">Aktiv bis: {{formatDate(priceModelToCreate.valid_to)}}</label>
                <VueDatePicker id="datepickerTo" class="even-flex-basis"
                               v-model="priceModelToCreate.valid_to"
                               locale="de"
                               :inline="true"
                               text-input auto-apply
                               format="dd.MM.yyyy HH:mm"
                               select-text="Speichern"
                               cancel-text="Abbrechen"/>
              </div>


            </div>
          </div>


          <div class="d-flex flex-column pa-4">

          </div>

        </v-card-text>
        <v-card-actions>
          <div class="d-flex flex-row mr-auto">
            <button @click="closeCreate" class="button-standard-back mr-3">Abbrechen</button>
            <button type="submit" class="button-standard">Speichern</button>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import {useMaskStore} from "@/stores/MaskStore";
import {useTargetGroupStore} from "@/stores/TargetGroupStore";
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment-timezone";
import {de} from "date-fns/locale";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {formatDate} from "../../../services/Helper.service";

export default {
  components: {VueDatePicker},

  setup() {
    const clientModelStore = useClientModelStore();
    const toast = useToast();
    const targetGroupStore = useTargetGroupStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const maskStore = useMaskStore();
    return {clientModelStore, toast, maskStore, targetGroupStore, priceModelTypeStore}
  },


  async mounted() {
    await this.targetGroupStore.getTargetGroups();
    await this.maskStore.getMasks();

    if(this.priceModelTypeStore.priceModelTypeData.length <= 0){
      await this.priceModelTypeStore.getPriceModelTypes();
    }
  },

  methods: {
    formatDate,

    closeCreate(e) {
      this.$emit("close-create-dialog");
      e.preventDefault();
    },

    async createItemConfirm() {

        this.priceModelToCreate.valid_to = moment(this.priceModelToCreate.valid_to).toISOString();
        this.priceModelToCreate.valid_from = moment(this.priceModelToCreate.valid_from).toISOString();

        this.$emit("confirm-create-dialog", this.priceModelToCreate);     }

  },

  data() {
    return {

      priceModelToCreate: {
        price_model_type_id: 1,
        active: true,
        valid_from: moment(),
        valid_to: moment().add(2, "hours"),
      },

      priceModelTypes: ["KlientenModell", "PacketModell"],

      pickerConfig: {
        format: 'DD-MM-YYYY HH:mm',
      },

      activeOrInactive: [{title: 'Aktiv', value: true}, {title: 'Inaktiv', value: false}],

      positionRule: [
        v => !!v || 'Pflichtfeld',
        v => {
          const regex = /^[A-Za-z0-9]{3,6}$/;
          if (!regex.test(v)) {
            return 'Der Name muss alphanumerisch sein und aus 3 bis 6 Zeichen bestehen';
          }
          return true;
        }
      ],
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
  },
  computed: {
    de() {
      return de
    },

    isDialogOpen() {
      return this.dialogOpen;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

.v-card{
}

</style>
