<template>

  <div class="page-container">
    <div class="d-flex flex-column">

      <PageTitle title="Nachrichten"/>
      <NewsTable/>
    </div>
  </div>

</template>

<script>

import PageTitle from "@/components/PageTitle.vue";
import NewsTable from "@/components/table/news/NewsTable.vue";


export default {
  components: {
    NewsTable,
    PageTitle
  }
}


</script>

<style scoped>

.table-container {
  border     : 1px solid var(--border-color);
  max-height : 100vh;
  overflow-y : auto;
}

td {
  color       : var(--table-data-text-color);
  font-family : var(--main-font-family);
}

th {
  color : #607081;
}

.even-row {
  background-color : #f9fafb; /* Even row color */
}

.odd-row {
  background-color : white; /* Odd row color */
}

.no-data-container {
  background-color : white;
}

</style>
