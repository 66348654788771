<template>

  <div class="d-flex flex-row align-center justify-center" v-if="isActive">

    <div class="green-dot"> </div>
    <div> Aktiv </div>

  </div>

  <div class="d-flex flex-row align-center justify-center" v-if="!isActive">

    <div class="red-dot"> </div>
    <div> Inaktiv </div>

  </div>




</template>


<script>

export default {


  props:{
    isActive:{
      required: true
    }
  }
}

</script>

<style scoped>

.green-dot {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%; /* Rund machen, um einen Punkt zu erstellen */
  display: inline-block;
}

.red-dot {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%; /* Rund machen, um einen Punkt zu erstellen */
  display: inline-block;
}

</style>

