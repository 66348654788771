<template>

  <div class="page-container">
    <div class="d-flex flex-column">

      <PriceModelTable/>

    </div>
  </div>

  <ConfirmDeleteDialog title="Bist du sicher das du dieses Preismodell löschen möchtest?"
                       v-model:dialog-open="dialogDelete" @close-delete-dialog="dialogDeleteActionClose"
                       @delete-dialog-item="dialogConfirmDeleteItem"/>

  <CreatePriceModelDialog v-model:dialog-open="dialogCreate" @close-create-dialog="dialogCreateActionClose"
                          @confirm-create-dialog="dialogConfirmCreateItem"/>

</template>

<script>
import {usePriceModelStore} from "@/stores/PriceModelStore";
import {inject} from "vue";
import ConfirmDeleteDialog from "@/components/table/ConfirmDeleteDialog.vue";
import {useToast} from "vue-toastification";
import CreatePriceModelDialog from "@/components/table/priceModel/CreatePriceModelDialog.vue";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import PriceModelTable from "@/components/table/priceModel/PriceModelTable.vue";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {
  components: {
    PriceModelTable,
    CreatePriceModelDialog,
    ConfirmDeleteDialog,
  },


  setup() {

    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const pageHeaderStore = usePageHeaderStore();
    const toast = useToast();
    const moment = inject('$moment')
    return {priceModelStore, moment, toast, priceModelTypeStore, pageHeaderStore};

  },

  async mounted() {

    const breadcrumbs = [
      { title: 'Preismodelle' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    await this.priceModelStore.getPriceModels();
    await this.priceModelTypeStore.getPriceModelTypes();

  },

  data() {
    return {
      dialogDelete: false,
      dialogCreate: false,
      editedItem: {
        id: 0,
      },
    }
  },

  methods: {

    async refreshTable() {
      await this.priceModelStore.getPriceModels();
      this.toast.success("Tabelle aktualisiert");
    },

    async deleteItem(id) {
      this.editedItem.id = id;
      this.dialogDelete = true;
    },
    dialogDeleteActionClose() {
      this.dialogDelete = false;
    },
    async dialogConfirmDeleteItem() {
      if (this.editedItem) {
        await this.priceModelStore.deleteSinglePriceModel(this.editedItem.id)
        await this.priceModelStore.getPriceModels();
        this.dialogDeleteActionClose();
        this.toast.success("Preismodell erfolgreich gelöscht!")
      } else {
        throw Error("Kein Item zum editieren ausgewählt")
      }
    },

    createItem() {
      this.dialogCreate = true;
    },

    dialogCreateActionClose() {
      this.dialogCreate = false;
    },

    async dialogConfirmCreateItem(priceModel) {
      await this.priceModelStore.createSinglePriceModel(priceModel);
      await this.priceModelStore.getPriceModels();
      this.toast.success("Preismodell erfolgreich angelegt")
      this.dialogCreate = false;
    },
    translatePriceModelItemTypeIdToString(priceModelTypeId) {

      if (this.priceModelTypeStore.priceModelTypeData.length > 0) {

        const priceModelTypeObject = this.priceModelTypeStore.priceModelTypeData.find(function (obj) {
          if (Number(obj.id) === Number(priceModelTypeId)) {
            return obj;
          }
        });
        return priceModelTypeObject.name
      }
    }
  },
}


</script>

<style scoped>

.table-container {
  border: 1px solid var(--border-color);
  max-height: 100vh;
  overflow-y: auto;
}

td {
  color: var(--table-data-text-color);
  font-family: var(--main-font-family);
}

th {
  color: #607081;
}


.even-row {
  background-color: #F9FAFB; /* Even row color */
}

.odd-row {
  background-color: white; /* Odd row color */
}

.no-data-container {
  background-color: white;
}

</style>
