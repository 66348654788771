import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";
import {PriceModelModel} from "@/models/priceModel.model";
import {API_URL} from "@/services/Helper.service";

export const usePriceModelStore = defineStore('PriceModelStore', {

        state: () => {

            const priceModelData: PriceModelModel[] = [];

            return {
                priceModelData,
            }

        },
        actions: {


            async getPdfById(pdfId: any) {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/pdf/' + pdfId, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("pdf", data.data[0])

                return data.data[0];

            },


            async getPriceModels() {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/pricemodel/', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.priceModelData = data.data;


            },

            async deleteSinglePriceModel(idToDelete: any) {

                const userStore = useUserStore();
                const data = await axiosInstance.delete(API_URL + '/api/pricemodel/' + idToDelete, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });

            },

            async updateSinglePriceModel(idToUpdate: any, elementToUpdate: any) {
                const userStore = useUserStore();

                const formData = new FormData();

                for (const key in elementToUpdate) {
                    formData.append(key, elementToUpdate[key]);
                }

                const data = await axiosInstance.put(API_URL + '/api/pricemodel/' + idToUpdate, formData, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`,
                    },
                });
            },


            async createSinglePriceModel(elementToCreate: any) {
                const userStore = useUserStore();
                const data = await axiosInstance.post(API_URL + '/api/pricemodel', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            },
        },
        getters:
            {
                getPriceModelById: (state) => (priceModelId: any) => {

                    const priceModelObject = state.priceModelData.find(function (priceModel) {
                        if (Number(priceModel.id) === Number(priceModelId)) {
                            return priceModel;
                        }
                    });


                    if (priceModelObject != null) {
                        return priceModelObject;
                    } else {
                        return null
                    }
                },

                getActivePriceModel()
                    :
                    any {
                    return this.priceModelData.filter((priceModel: any) => priceModel.active === true);
                }
            }
        ,
        persist: false,
    })
;