import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const useBMPDosingUnitStore = defineStore('BMPDosingUnitStore', {

    state: () => {

        const bmpDosingUnitData: any[] = [];

        return {
            bmpDosingUnitData
        }

    },
    actions: {


        async importBMPDosingUnitData(bmpDosingUnitFile: any) {

            const userStore = useUserStore();
            const formData = new FormData();

            formData.append("bmp_dosing_unit_file", bmpDosingUnitFile);

            const data = await axiosInstance.post(API_URL + '/api/bmpDosingUnit/', formData, {
                headers: {
                    'Authorization': `token ${userStore.user.token}`,
                },
            });

        },


        async getBMPDosingUnitData() {

            const userStore = useUserStore();

            const data = await axiosInstance.get(API_URL + '/api/bmpDosingUnit/', {
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                }
            })
            this.bmpDosingUnitData = data.data;
        },
    },

    getters: {},
    persist: false,
});