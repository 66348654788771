import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import Home from '@/views/MatomoPage.vue'
import priceModel from '@/views/priceModel/PriceModelPage.vue'
import priceModelEdit from '@/views/priceModel/EditPriceModelPage.vue'
import masks from '@/views/MaskPage.vue'
import customers from '@/views/CustomerPage.vue'
import businessTypes from '@/views/BusinessTypePage.vue'
import ICDTenPage from '@/views/masterData/ICDTenPage.vue'
import ActionPlanPage from '@/views/odCare/ActionPlanPage.vue'
import BloodSugarPage from '@/views/odCare/BloodSugarPage.vue'
import BMPDosageForms from '@/views/masterData/BMPDosageFormsPage.vue'
import BMPSubHeadings from '@/views/masterData/BMPSubHeadings.vue'
import QRCode from '@/views/qrCode.vue'
import DayTimesPage from '@/views/odCare/DayTimesPage.vue'
import {usePageHeaderStore} from "@/stores/PageHeaderStore";
import employeeRights from '@/views/EmployeRights.vue'
import auth from "@/auth";
import UnauthorizedPage from "@/views/UnauthorizedPage.vue";
import newsPage from "@/views/NewsPage.vue";
import BMPDosingUnit from "@/views/masterData/BMPDosingUnit.vue";

const routes = [
    {
        path: '/preismodelle',
        name: 'pricemodels',
        component: priceModel,
        meta: {
            requiresAuth: true,
            backendName: 'Preismodelle',
        }
    },
    {
        path: '/preismodell-bearbeiten/:id',
        name: 'pricemodelEdit',
        component: priceModelEdit,
        meta: {
            requiresAuth: true,
            backendName: "Preismodelle"
        }
    },
    {
        path: '/masken',
        name: 'masks',
        component: masks,
        meta: {
            requiresAuth: true,
            backendName: "Preismodelle"
        }
    },
    {
        path: '/kunden',
        name: 'customer',
        component: customers,
        meta: {
            requiresAuth: true,
            backendName: "Kunden"
        }
    },
    {
        path: '/unternehmensformen',
        name: 'businessTypes',
        component: businessTypes,
        meta: {
            requiresAuth: true,
            backendName: "Preismodelle"
        }
    },
    {
        path: '/nachrichten',
        name: 'news',
        component: newsPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mitarbeiter',
        name: 'employees',
        component: employeeRights,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: UnauthorizedPage,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: "/icdten",
        name: "icdten",
        component: ICDTenPage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/bmp-dosage-forms",
        name: "bmpDosageForms",
        component: BMPDosageForms,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/bmp-sub-headings",
        name: "bmpSubHeadings",
        component: BMPSubHeadings,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/bmp-dosing-unit",
        name: "bmpDosingUnit",
        component: BMPDosingUnit,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/qr-code",
        name: "QRCode",
        component: QRCode,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/actionplan",
        name: "actionplan",
        component: ActionPlanPage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/bloodsugar",
        name: "bloodsugar",
        component: BloodSugarPage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/daytimes",
        name: "daytimes",
        component: DayTimesPage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/home"
    },
]

const router = createRouter({history: createWebHistory(), routes})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext)  => {

    const pageHeaderStore = usePageHeaderStore();
    pageHeaderStore.clearBreadcrumbs();
    pageHeaderStore.clearActions();

    const requiresAuth = to.meta.requiresAuth;
    const backendName = to.meta.backendName;

    if (requiresAuth && backendName) {
        if(!auth.loggedIn()) {
            router.replace("/unauthorized").then();
        }
        const userRights = await auth.getBackofficeRights();
        if (userRights.some((right) => right.BackofficeMask.name === backendName && right.read)) {
            next();
        } else {
            router.replace("/unauthorized").then();
        }
    } else {
        next();
    }
});

export default router
