<template>

  <div class="d-flex flex-row justify-space-between w-100 header-padding">

    <div class="d-flex flex-row">
      <v-breadcrumbs :items="pageHeaderStore.breadcrumbs">

          <template v-slot:item="{ item }">

            <template v-if="item.icon">
              <v-icon class="home-icon">{{ item.icon }}</v-icon>
            </template>
            <template v-else>
              {{ item.title }}
            </template>
          </template>
      </v-breadcrumbs>
    </div>

    <div class="d-flex flex-row">
      <button v-for="action in pageHeaderStore.actions" class="ma-1 header-button-padding flex-grow-0"
              :class="action.class" :key="action.label"
              @click="action.onClick">
        {{ action.label }}
      </button>
    </div>
  </div>

</template>

<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";

export default {

  setup() {

    const pageHeaderStore = usePageHeaderStore();

    return {pageHeaderStore}
  },

  computed: {
    auth() {
      return auth
    },

    getBreadCrumbs() {
      const items = this.pageHeaderStore.breadcrumbs;

      return items;
    }
  },
  data: () => ({
    items: [
      {
        title: 'Dashboard',
        disabled: false,
        href: 'breadcrumbs_dashboard',
      },
      {
        title: 'Link 1',
        disabled: false,
        href: 'breadcrumbs_link_1',
      },
      {
        title: 'Link 2',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
  }),
}

</script>

<style>

.header-button-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.header-padding {
  padding-left: 32px;
  padding-right: 32px;
}

.home-icon {
  font-size: 20px;
}

</style>
