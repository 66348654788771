<template>
  <div class="page-container">
    <div class="d-flex flex-column">

      <v-card class="flex-grow-1">

        <v-card-title>BMP - Darreichungsformen</v-card-title>

        <v-card-text>

          <div class="d-flex flex-column w-100">
            <p class="b1 mb-2">BMP - Darreichungsformen Datei Import</p>
            <v-file-input class="flex-grow-1" accept=".xml" @change="onFileSelectedProductDescription"/>
          </div>

        </v-card-text>
      </v-card>

      <BMPDosageFormTable/>

    </div>
  </div>
</template>


<script>

import auth from "@/auth";
import {usePageHeaderStore} from "@/stores/PageHeaderStore";
import {useToast} from "vue-toastification";
import {useBMPDosageFormStore} from "@/stores/masterData/BMPDosageFormStore";
import BMPDosageFormTable from "@/components/table/bmpDosageForm/BMPDosageFormTable.vue";

export default {
  components: {BMPDosageFormTable},
  computed: {
    auth() {
      return auth
    }
  },

  setup() {
    const pageHeaderStore = usePageHeaderStore();
    const toast = useToast();
    const bmpDosageFormStore = useBMPDosageFormStore();

    return {pageHeaderStore, bmpDosageFormStore, toast};
  },

  data() {
    return {
      bmpDosageFormFile: null,
    };
  },

  async mounted(){
    const breadcrumbs = [
      { title: 'BMP - Darreichungsformen' },
    ];

    this.pageHeaderStore.setBreadcrumbs(breadcrumbs);

    // Actions setzen
    const actions = [
      { label: 'Speichern', class: 'button-standard', onClick: this.saveBMPDosageFormFile },
    ];

    this.pageHeaderStore.setActions(actions);

  },

  methods: {
    onFileSelectedProductDescription(event) {
      this.bmpDosageFormFile = event.target.files[0];
    },

    async saveBMPDosageFormFile() {

      if(this.bmpDosageFormFile){
        await this.bmpDosageFormStore.importBMPDosageFormsData(this.bmpDosageFormFile);
        this.toast.success("Daten erfolgreich importiert")
        await this.bmpDosageFormStore.getBMPDosageFormsData();
      } else {
        this.toast.error("Keine Datei ausgewählt")
      }
    },
  },
}

</script>


<style scoped>

.even-flex-basis {
  flex-basis: 50%;
}

.datepicker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-card{
  margin-top: 32px;
  margin-bottom: 32px;}

.v-card-text {
  padding: 0;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.v-card-text {
  padding: 20px;
}

</style>
