import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import {API_URL} from "@/services/Helper.service";

export const useQrCodeStore = defineStore('QrCodeStore', {

        state: () => {

            const qrData: any = null;

            return {
                qrData
            }
        },
        actions: {

            async getQrCode(customerNumber: number) {

                const userStore = useUserStore();

                const data = await axiosInstance.get(API_URL + '/api/qrCode/kdnr/' + customerNumber, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                this.qrData = data.data.image;
            },
        },

        persist: false,
    })
;