<template>
  <v-dialog
      max-width="1500"
      v-model="isDialogOpen">

    <v-form ref="form" validate-on="input" @submit.prevent="updateItemConfirm">

      <v-card>
        <v-card-title>
          <div class="d-flex flex-row align-center justify-space-between">

            <div class="d-flex flex-row justify-start">
              <h3>Kunden - Supportzugriff</h3>
            </div>

            <!-- Unsichtbarer Platzhalter -->
            <div style="visibility: hidden;">
              <h3>Kunden & Vertragsdaten</h3>
            </div>
          </div>

        </v-card-title>

        <v-card-text>

          <div class="d-flex flex-row">
            <v-card-item class="w-100">
              <div class="d-flex flex-column">
                <v-label for="reason_support">Begründung - Datenzugriff- Support</v-label>
                <ckeditor :editor="editor" id="reason_support" v-model="getEditItem.reason_support"/>
              </div>
            </v-card-item>
          </div>

        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-row justify-end w-100">
            <button class="button-standard-back" @click="closeSupport">Abbrechen</button>
            <div class="ma-2"></div>
            <button class="button-standard" type="submit">Kundendaten aktualisieren</button>
          </div>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>


</template>


<script>

import {useClientModelStore} from "@/stores/ClientModelStore";
import {useToast} from "vue-toastification";
import {useContractStore} from "@/stores/ContractStore";
import {useCustomerStore} from "@/stores/CustomerStore";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue";
import {usePaymentMethodTypeStore} from "@/stores/PaymentMethodTypeStore";

export default {
  components: {ckeditor: CKEditor.component},

  setup() {
    const packageModelStore = useClientModelStore();
    const customerStore = useCustomerStore();
    const paymentMethodTypeStore = usePaymentMethodTypeStore();
    const contractStore = useContractStore();
    const toast = useToast();
    return {packageModelStore, toast, contractStore, customerStore, paymentMethodTypeStore}
  },

  methods: {
    closeSupport(e) {
      this.$emit("close-support-dialog");
      e.preventDefault();
    },

    async updateItemConfirm() {
      const isFormValid = await this.$refs.form.validate();

      if (isFormValid.valid) {

        const updateItem = {
          id: this.getEditItem.id,
          customer_number: this.getEditItem.customer_number,
        }

        this.$emit("update-support-dialog", updateItem);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    }
  },

  data() {
    return {
      editor: ClassicEditor,
    }
  },

  props: {
    dialogOpen: {
      required: true
    },
    editItem: {
      required: true
    },
  },
  computed: {

    isDialogOpen() {
      return this.dialogOpen;
    },
    getEditItem() {
      return this.editItem;
    },
  }
}

</script>


<style scoped>

.button-standard {
  color: white;
}

.v-card-title {
  border: 1px solid var(--border-color)
}

.v-card-actions {
  border: 1px solid var(--border-color)
}

</style>
