<template>
  <div>

    <v-data-table
        fixed-header
        :height="height"
        :items-per-page="itemsPerPage"
        :headers="headers"
        :items="items"
        :items-per-page-text="itemsPerPageText"
        item-value="name"
        class="elevation-1 custom-v-data-table"
    >
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <div class="d-flex flex-row w-50 pa-4 justify-end">
            <slot name="actions"></slot>
          </div>

        </v-toolbar>
      </template>

      <template v-for="name in slotNames" :key="name" v-slot:[name]="slotProps">
        <slot :name="name" v-bind="slotProps"></slot>
      </template>

      <template v-slot:no-data>
        <p v-if="!isLoading"> {{ noDataText }}</p>
        <div v-if="isLoading" class="d-flex flex-row justify-center">
          <span class="spinner"></span>
        </div>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import {VDataTable} from "vuetify/lib/components/VDataTable";

export default {

  computed: {
    slotNames() {
      return Object.keys(this.$slots).filter(name => name.startsWith('item.'));
    },
  },

  components: {
    VDataTable
  },

  props: {
    customItems: Array,
    isLoading: Boolean,
    headers: Array,
    items: Array,
    title: String,
    height: {
      type: String,
      default: '550px'
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    itemsPerPageText: {
      type: String,
      default: 'Elemente pro Seite'
    },
    noDataText: {
      type: String,
      default: 'Keine Daten'
    }
  },
}
</script>

<style scoped>
.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-data-table ::v-deep(.v-data-table-header__content) {
  font-weight: 600;
  font-size: 14px;
  font-family: var(--main-font-family);
}

.v-data-table ::v-deep(.v-data-table__td) {
  color: var(--table-data-text-color);
}

.v-toolbar {
  background: white !important;
}
</style>
