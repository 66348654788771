<template>

  <div class="pa-4 d-flex flex-row w-100 align-center container">
    <div class="d-flex flex-row">
    <p class="first-word main-color-1"> eva 3 &nbsp;</p>
    <p class="second-word main-color-2"> administration</p>
    </div>
  </div>

</template>


<style scoped>

.first-word {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 600;
  font-size: 21px;
}

.second-word {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 300;
  font-size: 21px;
}

.header-actions-container{
 margin-left: auto;
}

</style>


<script>

import auth from "@/auth";

export default {
  computed: {
    auth() {
      return auth
    }
  }
}


</script>
