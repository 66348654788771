import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";
import {API_URL} from "@/services/Helper.service";

export const useTargetGroupStore = defineStore('TargetGroupStore', {

    state: () => {

        const targetGroupData: any[] = [];

        return {
            targetGroupData
        }

    },
    actions: {

        async getTargetGroups() {

            const userStore = useUserStore();

            const data = await axiosInstance.get(API_URL + '/api/targetgroup/', {
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                }
            })
            this.targetGroupData = data.data;
        },
    },

    getters: {},
    persist: false,
});