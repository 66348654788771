<template>


  <abstract-data-table

      title="Produkte"
      :items="clientModelStore.clientModelDataByPriceModelData"
      :headers="headers"
  >

    <template #actions>
      <button @click="refreshTable" class="table-header-action-button">
        Tabelle aktualisieren
      </button>

      <div class="ma-2"/>

      <button @click="openCreateDialog" class="table-header-action-button" v-if="hasEditRights">
        Produkt anlegen
      </button>
    </template>

    <template #item.actions="{ item }">
      <button @click="editItem(item)" class="table-data-action-button" title="Bearbeiten">
        <v-icon>mdi-pencil</v-icon>
      </button>
      <button @click="deleteItem(item)" class="table-data-action-button" title="Löschen">
        <v-icon>mdi-trash-can</v-icon>
      </button>
    </template>

    <template #item.mask_id="{ item }">
      <p> {{ translateMaskIdToText(item.mask_id) }} </p>
    </template>

    <template #item.target_group_id="{ item }">
      <p> {{ clientModelStore.translateTargetGroupIdToText(item.target_group_id) }} </p>
    </template>

    <template #item.intensive="{ item }">
      <ColoredDot :is-active="item.intensive"/>
    </template>

    <template #item.intensive_clients="{ item }">
      <ColoredDot :is-active="item.intensive_clients"/>
    </template>

    <template #item.suggested="{ item }">
      <ColoredDot :is-active="item.suggested"/>
    </template>

    <template #item.active="{ item }">
      <ColoredDot :is-active="item.active"/>
    </template>

  </abstract-data-table>

  <EditClientModelDialog v-model:dialog-open="dialogEdit" :edit-item="editedItem"
                         @close-edit-dialog="closeDeleteDialogEdit"
                         @update-dialog-item="updateDialogItem"/>

  <ConfirmDeleteDialog title="Bist du sicher das du dieses Produkt löschen möchtest?"
                       v-model:dialog-open="dialogDelete"
                       @close-delete-dialog="closeDeleteDialogDelete"
                       @delete-dialog-item="dialogConfirmDeleteItem"/>

  <CreateClientModelDialog v-model:dialog-open="dialogCreateProduct"
                           @close-create-dialog="closeCreateDialogItem"
                           @confirm-create-item="dialogCreateItemConfirm"
                           :price-model-id="this.priceModelId"/>

</template>


<script>

import ConfirmDeleteDialog from "@/components/table/ConfirmDeleteDialog.vue";
import ColoredDot from "@/components/table/ColoredDot.vue";
import EditClientModelDialog from "@/components/table/clientModel/EditClientModelDialog.vue";
import {useClientModelStore} from "@/stores/ClientModelStore";
import {usePriceModelStore} from "@/stores/PriceModelStore";
import {usePriceModelTypeStore} from "@/stores/PriceModelTypeStore";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
import {inject} from "vue";
import CreateClientModelDialog from "@/components/table/clientModel/CreateClientModelDialog.vue";
import {hasEditRightsForMask, translateMaskIdToText} from "@/services/Helper.service";
import AbstractDataTable from "@/components/table/AbstractDataTable.vue";

export default {
  components: {
    AbstractDataTable,
    CreateClientModelDialog,
    EditClientModelDialog,
    ColoredDot,
    ConfirmDeleteDialog,
  },

  setup() {

    const clientModelStore = useClientModelStore();
    const priceModelStore = usePriceModelStore();
    const priceModelTypeStore = usePriceModelTypeStore();
    const toast = useToast();
    const router = useRouter();
    const moment = inject('$moment')

    return {
      clientModelStore,
      toast,
      router,
      priceModelStore,
      moment,
      priceModelTypeStore
    };
  },

  async mounted(){
    this.isLoading = true;
    this.hasEditRights = await hasEditRightsForMask(this.backofficeMask);
    await this.clientModelStore.getClientModelsByPriceModelId(this.priceModelId);
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      dialogDelete: false,
      dialogCreateProduct: false,
      dialogEdit: false,
      backofficeMask: 'Kunden',
      hasEditRights: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        position: 0,
        name: "",
        price_unique: 0,
        price_per_client: 0,
        minimum_amount: 0,
        description: "",
        target_group_id: 0,
        mask_id: 0,
        column: 0,
        order: 0,
        active: false,
        intensive: false,
        intensive_clients: false,
        suggested: false,
      },

      headers: [
        {title: 'ID', align: 'center', key: 'id', sortable: false, visible: false},
        {title: 'Position', align: 'center', key: 'position', sortable: false},
        {title: 'Bezeichnung', align: 'center', key: 'name', sortable: false},
        {title: 'Preis einmalig', align: 'center', key: 'price_unique', sortable: false},
        {title: 'Preis je Klient', align: 'center', key: 'price_per_client', sortable: false},
        {title: 'Mindestanzahl', align: 'center', key: 'minimum_amount', sortable: false},
        {title: 'Maske', align: 'center', key: 'mask_id', sortable: false},
        {title: 'Spalte', align: 'center', key: 'column', sortable: false},
        {title: 'Reihenfolge', align: 'center', key: 'order', sortable: false},
        {title: 'Intensiv', align: 'center', key: 'intensive', sortable: false},
        {title: 'Klienten (intensiv)', align: 'center', key: 'intensive_clients', sortable: false},
        {title: 'Empfohlen', align: 'center', key: 'suggested', sortable: false},
        {title: 'Aktiv', align: 'center', key: 'active', sortable: false},
        {title: 'Aktionen', align: 'center', key: 'actions', sortable: false},
      ],
    }
  },

  methods: {
    translateMaskIdToText,
    async refreshTable() {
      await this.clientModelStore.getClientModelsByPriceModelId(this.priceModelId);
      this.toast.success("Tabelle aktualisiert");
    },

    openCreateDialog() {
      this.dialogCreateProduct = true;
    },

    closeDeleteDialogEdit() {
      this.dialogEdit = false;
    },

    closeDeleteDialogDelete() {
      this.dialogDelete = false;
    },

    closeCreateDialogItem() {
      this.dialogCreateProduct = false;
    },

    async dialogConfirmDeleteItem() {
      await this.clientModelStore.deleteSingleClientModel(this.editedItem.id)
      await this.clientModelStore.getClientModelsByPriceModelId(this.priceModelId);
      this.closeDeleteDialogDelete();
      this.toast.success("Produkt erfolgreich gelöscht!")
    },

    async updateDialogItem(editItemFromDialog) {
      await this.clientModelStore.updateSingleClientModel(editItemFromDialog.id, editItemFromDialog);
      await this.clientModelStore.getClientModelsByPriceModelId(this.priceModelId);
      this.toast.success("Produkt erfolgreich aktualisiert!")
    },

    async dialogCreateItemConfirm(newProduct) {
      await this.clientModelStore.createSingleClientModel(newProduct);
      await this.clientModelStore.getClientModelsByPriceModelId(this.priceModelId);
      this.toast.success("Produkt erfolgreich angelegt!")
      this.dialogCreateProduct = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true;
    },
  },

  props: {
    priceModelId: {
      required: true
    }
  }
}

</script>

<style scoped>

.custom-v-data-table {
  overflow: scroll;
}

.v-data-table ::v-deep(.v-data-table-footer) {
  position: sticky;
  bottom: 0;
}

.v-toolbar {
  background: white !important;
}

</style>

