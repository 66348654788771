<template>
  <v-dialog
      max-width="625"
      v-model="isDialogOpen">

    <v-card>
      <v-card-title>
        <div class="d-flex flex-row justify-start w-100">
          <h3>Nachricht erstellen</h3>
        </div>
      </v-card-title>

      <v-card-text>

        <v-form ref="form" validate-on="input" @submit.prevent="createItemConfirm">

          <div class="d-flex flex-column">

            <v-card-item class="w-100">
              <div class="d-flex flex-column">
                <v-label for="position">Titel</v-label>
                <v-text-field :rules="titleRule" id="title" v-model="newItem.titel" type="text"
                ></v-text-field>
              </div>
            </v-card-item>

            <v-card-item class="w-100">
              <div class="d-flex flex-column">
                <v-label for="description">Nachricht</v-label>
                <ckeditor :rules="newsRule" :editor="editor" id="news" v-model="newItem.nachricht"
                          :config="editorConfig"
                />
              </div>
            </v-card-item>


            <label>
              <input type="checkbox" id="checkbox" v-model="checked" @change="onChecked($event)"/>
              kein Enddatum angeben</label>

            <div class="datepicker-container ml-3 w-50">
              <label for="datepickerFrom" class="poppins">Gültig bis: {{ formatValidToDate }}</label>
              <VueDatePicker id="datepickerTo" class="even-flex-basis"
                             v-model="newItem.ablaufdatum"
                             :format-locale="de"
                             timezone="UTC"
                             :inline="true"
                             text-input auto-apply
                             format="dd.MM.yyyy"
                             :config="pickerConfig"
                             :disabled="isChecked"
                             select-text="Speichern"
                             cancel-text="Abbrechen"
              />
            </div>

          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex flex-row mr-auto">
          <button @click="closeCreate" class="button-standard-back mr-3">Abbrechen</button>
          <button @click="createItemConfirm" class="button-standard">Speichern</button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>


<script>

import VueDatePicker from "@vuepic/vue-datepicker";
import {useNewsModelStore} from "@/stores/NewsModelStore";
import {useToast} from "vue-toastification";
import moment from "moment-timezone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";


export default {
  components: {VueDatePicker, ckeditor: CKEditor.component},

  setup() {
    const newsStore = useNewsModelStore();
    const toast = useToast();
    return {newsStore, toast}
  },

  methods: {


    closeCreate(e) {
      this.$emit("close-create-dialog");
      e.preventDefault();
    },

    async createItemConfirm() {
      const isFormValid = await this.$refs.form.validate();
      if (isFormValid.valid) {
        this.ablaufdatum = moment(this.ablaufdatum).utc().toISOString();
        this.$emit("confirm-create-dialog", this.newItem);
      } else {
        this.toast.error("Validierung Fehlgeschlagen! Überprüfe deine Eingabe");
      }
    },

    onChecked(event) {
      if (event.target.checked) {
        this.isChecked = true;
        this.ablaufdatum = moment().add(50, 'years').format('YYYY-MM-DD');
        return true;
      } else {
        this.isChecked = false;
      }
    }
  },

  data() {
    return {
      isChecked: false,
      newItem: {
        titel: "",
        nachricht: "",
        ablaufdatum: moment()
      },

      titleRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^.{1,255}$/;
          if (!regex.test(v)) {
            return 'Der eingegebene Text überschreitet die maximale Zeichenlänge von 255 Zeichen. Bitte kürzen Sie den Text auf maximal 255 Zeichen.';
          }
          return true;
        }
      ],

      newsRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^.{1,1000}$/;
          if (!regex.test(v)) {
            return 'Der eingegebene Text überschreitet die maximale Zeichenlänge von 1000 Zeichen. Bitte kürzen Sie den Text auf maximal 1000 Zeichen.';
          }
          return true;
        }
      ],

      pickerConfig: {
        format: 'DD-MM-YYYY',
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      }

    }
  },
  props: {
    dialogOpen: {
      required: true,

    },

  },
  computed: {
    isDialogOpen() {
      return this.dialogOpen;
    },

    formatValidToDate() {
      return moment(this.newItem.ablaufdatum).format("LL");
    },


  }
}
</script>

<style scoped>

.button-standard {
  color : white;
}

.v-card-title {
  border : 1px solid var(--border-color)
}

.v-card-actions {
  border : 1px solid var(--border-color)
}

</style>